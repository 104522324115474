import {
  Code as CodeIcon,
  Comment as CommentIcon,
  Link as LinkIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Share as ShareIcon,
} from '@mui/icons-material';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { IconButtonProps } from '@mui/material/IconButton';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { toast } from 'react-hot-toast';
import slugify from 'slugify';

import copyToClipboard from '../../utils/copyToClipboard';
import getEmbedRoute from '../../utils/getEmbedRoute';
import htmlToPdf from '../../utils/htmlToPdf';
import styles from './Share.module.scss';

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export interface IProps {
  className?: string;
  data: WithRequired<ShareData, 'title' | 'url'>;
  type: 'article' | 'toc';
  size?: IconButtonProps['size'];
  elementToPdf?: ReactElement;
  onEmbedClick?: () => void;
  onCommentClick?: () => void;
}

const Share = ({
  className,
  type,
  size,
  data,
  elementToPdf,
  onEmbedClick,
  onCommentClick,
}: IProps) => {
  const tooltips = useMemo(() => {
    return {
      article: {
        embed: 'Osadź artykuł',
        share: 'Udostępnij artykuł',
        link: 'Kopiuj link do artykułu',
        pdf: 'Pobierz PDF z artykułem',
        comment: 'Zadaj pytanie autorowi artykułu',
      },
      toc: {
        share: 'Udostępnij rozdział',
        link: 'Kopiuj link do rozdziału',
        pdf: 'Pobierz PDF z rozdziałem',
        comment: 'Zadaj pytanie autorowi rozdziału',
      },
    }[type];
  }, [type]);

  const isEmbed = !!getEmbedRoute();

  const isShareSupport = useMemo(() => {
    let isSupported;

    try {
      isSupported = navigator.canShare(data);
    } catch {
      isSupported = false;
    }

    return isSupported;
  }, [data]);

  const onLinkClick = useCallback(() => {
    copyToClipboard(data.url)
      .then(() => {
        toast.success('Skopiowano link do schowka', {
          position: 'bottom-left',
        });
      })
      .catch(() => {
        toast.error('Wystąpił błąd podczas kopiowania linku', {
          position: 'bottom-left',
        });
      });
  }, [data.url]);

  const onShareClick = useCallback(() => {
    try {
      navigator.share(data);
    } catch {
      onLinkClick();
    }
  }, [JSON.stringify(data)]);

  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const onPDFClick = useCallback(async () => {
    if (!elementToPdf) return;

    setIsLoadingPdf(true);

    const filename = `${slugify(data.title, {
      lower: true,
    })}.pdf`;
    const html = ReactDOMServer.renderToString(elementToPdf);

    await htmlToPdf({
      filename,
      html,
    });

    setIsLoadingPdf(false);
  }, [elementToPdf, data.title]);

  const gap = useMemo(() => {
    if (size === 'small') return '5px';
    if (size === 'large') return '15px';
    return '10px';
  }, [size]);

  const progressSize = useMemo(() => {
    if (size === 'small') return '18px';
    if (size === 'large') return '28px';
    return '24px';
  }, [size]);

  return (
    <div className={className}>
      <div
        className={styles.share}
        style={{
          gap,
        }}
      >
        {isShareSupport ? (
          <Tooltip title={tooltips.share}>
            <IconButton size={size} color="inherit" onClick={onShareClick}>
              <ShareIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={tooltips.link}>
            <IconButton size={size} color="inherit" onClick={onLinkClick}>
              <LinkIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
        {type === 'article' && !isEmbed && onEmbedClick && (
          <Tooltip title={tooltips.embed}>
            <IconButton size={size} color="inherit" onClick={onEmbedClick}>
              <CodeIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
        {onCommentClick && (
          <Tooltip title={tooltips.comment}>
            <IconButton size={size} color="inherit" onClick={onCommentClick}>
              <CommentIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
        {elementToPdf && (
          <Tooltip title={tooltips.pdf}>
            <IconButton size={size} color="inherit" onClick={onPDFClick}>
              {isLoadingPdf ? (
                <CircularProgress color="inherit" size={progressSize} />
              ) : (
                <PictureAsPdfIcon fontSize="inherit" />
              )}
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

Share.defaultProps = {
  className: undefined,
  size: undefined,
  elementToPdf: undefined,
  onEmbedClick: undefined,
  onCommentClick: undefined,
};

export default Share;
