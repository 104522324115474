import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';

import globalStyles from '../../styles/global.module.scss';
import { CustomTextField } from '../CustomTextField';
import styles from './ArticlePasswordDialog.module.scss';

interface IProps {
  onSubmit: (password: string) => void;
  onCancel: () => void;
}

const ArticlePasswordDialog = ({ onSubmit, onCancel }: IProps) => {
  // formik
  const [validationOnChange, setValidationOnChange] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    onSubmit: (values) => {
      onSubmit(values.password);
      /* eslint-disable-next-line @typescript-eslint/no-use-before-define */
      handleDialogClose();
    },
    validate: (values) => {
      const ValidationFields = ['password'] as const;
      type Tuple = typeof ValidationFields;
      type ValidationFieldType = Tuple[number];
      const errors = {} as Record<ValidationFieldType, string>;

      ValidationFields.forEach((key) => {
        if (!values[key as ValidationFieldType]) {
          errors[key as ValidationFieldType] = 'Pole jest wymagane!';
        }
      });

      if (Object.keys(errors).length > 0) {
        setValidationOnChange(true);
      }

      return errors;
    },
    validateOnChange: validationOnChange,
    validateOnBlur: validationOnChange,
  });

  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      document.getElementById(Object.keys(formik.errors)[0])?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [formik]);

  // dialog
  const resetStates = () => {
    setValidationOnChange(false);
    formik.resetForm();
  };

  const [open, setOpen] = useState(true);
  const handleDialogClose = () => {
    resetStates();
    setOpen(false);
    onCancel();
  };

  const handleDialogConfirm = () => formik.submitForm();

  return (
    <Dialog
      className={styles.dialog}
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Wprowadź hasło dostępu</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Artykuł wymaga podania hasła dostępu:
        </DialogContentText>
        <form
          onSubmit={formik.handleSubmit}
          className={[globalStyles.form, styles.form].join(' ')}
        >
          <CustomTextField
            id="password"
            label="Hasło dostępu"
            variant="outlined"
            type="password"
            className={globalStyles.formRow}
            onChange={formik.handleChange}
            value={formik.values.password || ''}
            error={!!formik.errors.password}
          />
          <FormHelperText className={globalStyles.formError}>
            {formik.errors.password}
          </FormHelperText>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>Anuluj</Button>
        <Button
          onClick={handleDialogConfirm}
          variant="contained"
          color="primary"
          autoFocus
        >
          Wyślij
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArticlePasswordDialog;
