export enum Path {
  // shared
  ROOT = '/',
  ACCESS_DENIED = '/wstep-zabroniony',
  NOT_AUTHORIZED = '/niezautoryzowany',
  ARTICLE = `/artykul`,
  ARTICLE_EMBED = `/artykul/embed`,
  ARTICLES = `/artykuly`,
  INFOPAGE = '/o-projekcie',
  EDUCATION = `/materialy-edukacyjne`,
  WHO = `/wytyczne-who`,
  NICE = `/wytyczne-nice`,
  COCHRANE = `/cochrane`,
  VIEWPDF = `/view-pdf`,
  INFOGRAPHICS = `/infographics`,
  EBM = `/ebm`,

  // main
  AUTHORS = '/autorzy',
  AUTHOR = '/autor',
  LOGIN = '/logowanie',

  // admin
  ADMIN = '/admin',
  ADMIN_MAIN_PAGE = '',
  ADMIN_ARTICLE = '/article',
  AUTHOR_PANEL = '/panel-autora',
  EDIT_PROFILE = '/edytuj-profil',
  EDIT_PASSWORD = '/zmien-haslo',
  EDIT_ARTICLE = '/edytuj-artykul',
  EDIT_TOC = '/edytuj-rozdzial',
  ADD_TOC = '/dodaj-rozdzial',
}
