import { matchPath, useLocation } from 'react-router-dom';

import { Path } from '../router/paths';

const embedRoutes = [`${Path.ARTICLE_EMBED}/:id`];

const getEmbedRoute = (): string | undefined => {
  const { pathname } = useLocation();
  return embedRoutes.find((route) => matchPath(route, pathname));
};

export default getEmbedRoute;
