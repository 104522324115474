import { AxiosPromise } from 'axios';

import axiosInstance from '../axiosInstance';
import { baseAPIUrl } from '../config';
import { UserDto } from '../models/UserDto';

export const getAuthors = (): AxiosPromise<UserDto[]> => {
  return axiosInstance({
    url: `${baseAPIUrl}/authors`,
    method: 'GET',
  });
};

export const getAuthor = (id: number): AxiosPromise<UserDto> => {
  return axiosInstance({
    url: `${baseAPIUrl}/authors/${id}`,
    method: 'GET',
  });
};
