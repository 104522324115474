import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

import { createComment } from '../../api/services/article';
import globalStyles from '../../styles/global.module.scss';
import { isValidEmail } from '../../utils/isValidEmail';
import { CustomTextField } from '../CustomTextField';
import styles from './ArticleCommentDialog.module.scss';

export type TArticleCommentDialogData = {
  articleId: number;
  articleTocId?: number;
};

interface IProps {
  data?: TArticleCommentDialogData;
  onClose: () => void;
}

const ArticleCommentDialog = ({ data, onClose }: IProps) => {
  // formik
  const [validationOnChange, setValidationOnChange] = useState(false);
  const { mutateAsync: postComment, isLoading: isCommentLoading } = useMutation(
    {
      mutationFn: createComment,
      onSuccess: () => {
        toast.success('Poprawnie wysłano zapytanie.', {
          duration: 5000,
          position: 'bottom-left',
        });
      },
      onError: () => {
        toast.error('Wystąpił błąd podczas wysyłania.', {
          duration: 5000,
          position: 'bottom-left',
        });
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      comment: '',
    },
    onSubmit: (values) => {
      if (!data) return;

      const { articleId, articleTocId } = data;
      const vals = {
        ...values,

        articleId,
        articleTocId,
      };

      postComment(vals).then(() => {
        /* eslint-disable-next-line @typescript-eslint/no-use-before-define */
        handleDialogClose();
      });
    },
    validate: (values) => {
      const ValidationFields = ['name', 'email', 'comment'] as const;
      type Tuple = typeof ValidationFields;
      type ValidationFieldType = Tuple[number];
      const errors = {} as Record<ValidationFieldType, string>;

      ValidationFields.forEach((key) => {
        if (!values[key as ValidationFieldType]) {
          errors[key as ValidationFieldType] = 'Pole jest wymagane!';
        }
      });

      if (!errors.email && !isValidEmail(values.email)) {
        errors.email = 'Adres e-mail ma nieprawidłowy format!';
      }

      if (!errors.comment && values.comment.length < 10) {
        errors.comment = 'Komentarz musi mieć conajmniej 10 znaków!';
      }

      if (Object.keys(errors).length > 0) {
        setValidationOnChange(true);
      }

      return errors;
    },
    validateOnChange: validationOnChange,
    validateOnBlur: validationOnChange,
  });

  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      document.getElementById(Object.keys(formik.errors)[0])?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [formik]);

  // dialog
  const resetStates = () => {
    setValidationOnChange(false);
    formik.resetForm();
  };

  const handleDialogClose = () => {
    resetStates();
    onClose();
  };

  const handleDialogConfirm = () => formik.submitForm();

  return (
    <Dialog
      className={styles.dialog}
      open={!!data}
      onClose={handleDialogClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Zadaj pytanie autorowi</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Napisz nam swoje pytanie, a my postaramy się odpowiedzieć tak szybko
          jak to możliwe.
        </DialogContentText>
        <form
          onSubmit={formik.handleSubmit}
          className={[globalStyles.form, styles.form].join(' ')}
        >
          <CustomTextField
            id="name"
            label="Imię i nazwisko"
            variant="outlined"
            className={globalStyles.formRow}
            onChange={formik.handleChange}
            value={formik.values.name || ''}
            error={!!formik.errors.name}
          />
          <FormHelperText className={globalStyles.formError}>
            {formik.errors.name}
          </FormHelperText>
          <CustomTextField
            id="email"
            label="Adres e-mail"
            variant="outlined"
            type="email"
            className={globalStyles.formRow}
            onChange={formik.handleChange}
            value={formik.values.email || ''}
            error={!!formik.errors.email}
          />
          <FormHelperText className={globalStyles.formError}>
            {formik.errors.email}
          </FormHelperText>
          <CustomTextField
            id="comment"
            label="Treść pytania"
            variant="outlined"
            rows={4}
            multiline
            className={globalStyles.formRow}
            onChange={formik.handleChange}
            value={formik.values.comment || ''}
            error={!!formik.errors.comment}
          />
          <FormHelperText className={globalStyles.formError}>
            {formik.errors.comment}
          </FormHelperText>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>Anuluj</Button>
        <LoadingButton
          onClick={handleDialogConfirm}
          variant="contained"
          color="primary"
          loading={isCommentLoading}
          autoFocus
        >
          Wyślij
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ArticleCommentDialog.defaultProps = {
  data: undefined,
};

export default ArticleCommentDialog;
