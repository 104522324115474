import { ArticleDto } from './ArticleDto';

export enum ArticlePositionPlace {
  MainSlider = 'main-slider',
  MainRecommended = 'main-recommended',
  PhysioTiles = 'physio-tiles',
  PatientTiles = 'patient-tiles',
  Physio = 'physio',
  Patient = 'patient',
}

export type ArticlePositionDto = {
  id: number;
  place: ArticlePositionPlace;
  article: ArticleDto;
};
