import { ReactNode, useEffect, useMemo } from 'react';
/* eslint-disable-next-line simple-import-sort/imports */
import { matchPath, NavLink, Params, useLocation } from 'react-router-dom';

import {
  FooterSign,
  KifLogo,
  Logo,
  LogoFooter,
} from '../../assets/images/logo';
import fe_ue from '../../assets/images/pics/layout/fe_ue.png';
import { Path } from '../../router/paths';
import getEmbedRoute from '../../utils/getEmbedRoute';
import { Navigation } from '../Navigation';
import styles from './Layout.module.scss';

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const getEmbed = getEmbedRoute();
  const isEmbed = !!getEmbed;

  const { pathname } = useLocation();
  const params = useMemo<Params<string>>(() => {
    if (getEmbed) {
      return matchPath(getEmbed, pathname)?.params || {};
    }
    return {};
  }, [getEmbed]);

  const { id } = params;

  useEffect(() => {
    if (isEmbed) {
      const docEl = document.documentElement;
      docEl.dataset.embed = 'true';

      let lastHeight: number;
      const intervalId = setInterval(() => {
        const height = Math.min(docEl.scrollHeight, docEl.offsetHeight);
        if (lastHeight !== height) {
          window.parent.postMessage(
            {
              [`infodemia-embed-${id}`]: {
                height,
              },
            },
            '*'
          );
          lastHeight = height;
        }
      }, 500);

      return () => {
        clearInterval(intervalId);
        delete docEl.dataset.embed;
      };
    }

    return undefined;
  }, [isEmbed]);

  return (
    <div className={styles.mainBody}>
      {!isEmbed && (
        <div className={styles.navPanel}>
          <div className={styles.logo}>
            <NavLink to={`${Path.ROOT}`} target="_self" className={styles.link}>
              <img src={Logo} alt="logo" />
            </NavLink>
          </div>
          <div className={styles.navbar}>
            <Navigation />
          </div>
        </div>
      )}

      <div className={styles.mainPanel}>
        {!isEmbed && (
          <div className={styles.mainUe}>
            <div className={styles.mainUeBox}>
              <img src={fe_ue} alt="Loga Ue" />
              <br />
              Sfinansowano w ramach reakcji Unii na pandemię COVID-19.
            </div>
          </div>
        )}
        {children}
      </div>
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          fontSize: '13px',
          marginBottom: '50px',
          fontWeight: 'bold',
        }}
      >
        Materiały edukacyjne dla pacjentów zamieszone na stronie nie zastępują
        porady fizjoterapeutycznej
      </div>
      {!isEmbed && (
        <footer className={styles.footer}>
          {' '}
          <NavLink className={styles.footerLogo} to={Path.ROOT}>
            <img src={LogoFooter} alt="Infodemia" />
          </NavLink>
          <div className={styles.footerLeft}>
            <a href="https://kif.info.pl" className={styles.kifLogo}>
              <img src={KifLogo} alt="KIF" />
            </a>
            <a href="infodemia_regulamin.pdf" target="_blank">
              Regulamin
            </a>
            <a href="infodemia_polityka_prywatnosci.pdf" target="_blank">
              Polityka Prywatności
            </a>
            <a href="https://kif.info.pl" target="_blank" rel="noreferrer">
              KIF
            </a>
            <a href="mailto:serwis@kif.info.pl">serwis@kif.info.pl</a>
          </div>
          <div className={styles.footerRight}>
            <img className={styles.footerSign} src={FooterSign} alt="" />
          </div>
        </footer>
      )}
    </div>
  );
};

export default Layout;
