import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';

import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';

import { TriangleIcon } from '../../assets/icons';
import { Path } from '../../router/paths';
import styles from './Slider.module.scss';

interface ISlide {
  id: number;
  title: string;
  img: string;
}

export interface IProps extends SwiperProps {
  slides: ISlide[];
}

export const Slider = ({ slides, ...props }: IProps) => {
  return (
    <Swiper
      className={styles.slider}
      loop
      autoplay={{
        pauseOnMouseEnter: true,
      }}
      navigation
      modules={[Navigation, Autoplay]}
      {...props}
    >
      {slides.map((slide) => (
        <SwiperSlide className={styles.slide} key={slide.id}>
          <figure className={styles.figure}>
            <img className={styles.img} src={slide.img} alt="" />
          </figure>
          <div className={styles.box}>
            <TriangleIcon
              className={styles.triangle}
              color="#76446B"
              inHeader={false}
            />
            <div className={styles.info}>
              dla {slide.id === 10 ? 'pacjenta' : 'fizjoterapeuty'}
            </div>
            <h2 className={styles.title}>{slide.title}</h2>
            <div className={styles.buttonWrap}>
              <Button
                className={styles.button}
                component={Link}
                to={`${Path.ARTICLE}/${slide.id}`}
                target="_self"
              >
                Przeczytaj
              </Button>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
export default Slider;
