import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { ArticleDto } from '../../../../api/models/ArticleDto';
import { UserDto } from '../../../../api/models/UserDto';
import { getArticles } from '../../../../api/services/article';
import { getAuthor } from '../../../../api/services/user';
import { Avatar } from '../../../../assets/icons';
import {
  ArticleContainer,
  ArticleItem,
} from '../../../../components/ArticleItem';
import { EArticleItemType } from '../../../../components/ArticleItem/ArticleItem';
import { CustomLoading } from '../../../../components/CustomLoading';
import { Path } from '../../../../router/paths';
import globalStyles from '../../../../styles/global.module.scss';
import styles from './Author.module.scss';

const Author = () => {
  const { id } = useParams();
  const isEnabled = id !== undefined;

  const {
    isLoading,
    error,
    data: author,
  } = useQuery<UserDto, AxiosError>({
    queryKey: ['author', id],
    queryFn: () => getAuthor(parseInt(id!, 10)).then((res) => res.data),
    enabled: isEnabled,
  });

  const { data: articlesData, isLoading: isArticlesLoading } = useQuery<
    ArticleDto[],
    AxiosError
  >({
    queryKey: ['articles'],
    queryFn: () => getArticles().then((res) => res.data),
  });

  const [articles, setArticles] = useState<ArticleDto[]>();
  useEffect(() => {
    if (!author || !articlesData) return;

    const arts = articlesData.filter((article) => {
      return (
        article.user.id === author.id ||
        article.authors.find((a) => {
          return a.id === author.id;
        })
      );
    });
    setArticles(arts);
  }, [author, articlesData]);

  if (isLoading || isArticlesLoading) {
    return <CustomLoading />;
  }

  if (error) {
    return <div>Wystąpił błąd... {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>{author.name} - Ekspert w Projekt Infodemia KIF</title>
      </Helmet>
      <div className={`${globalStyles.container} ${styles.arts}`}>
        <div className={styles.author}>
          <div className={styles.authorLeft}>
            <div className={styles.authorName}>{author.name}</div>
            <div
              className={styles.authorDesc}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: author.bio || '' }}
            />
          </div>
          <div className={styles.authorRight}>
            <div
              className={styles.authorImg}
              style={{
                backgroundImage: `url(${author.avatar || Avatar})`,
              }}
            />
          </div>
        </div>
      </div>
      <div className={`${globalStyles.container} ${styles.container}`}>
        {articles && articles.length > 0 && (
          <ArticleContainer>
            <ArticleItem type={EArticleItemType.ADD}>
              <div>Ostatnio</div>
              <div>Dodane</div>
              <div>treści</div>
            </ArticleItem>

            {articles.map((article) => {
              const authors = [article.user, ...article.authors].filter(
                (value, index, self) => {
                  return self.findIndex((a) => a.id === value.id) === index;
                }
              );

              return (
                <ArticleItem
                  key={article.id}
                  category={article.category.name}
                  date={article.update_time.toLocaleDateString('pl-PL')}
                  href={`${Path.ARTICLE}/${article.id}`}
                  title={article.title}
                  lead={article.lead}
                  authors={authors}
                />
              );
            })}
          </ArticleContainer>
        )}
      </div>
    </>
  );
};

export default Author;
