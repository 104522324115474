import { TaskOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { CookiesProvider } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Prezentacja from '../../../../assets/materials/nice/prezentacje/prezentacja.jpg';
import WytyczneFizjoterapeuta from '../../../../assets/materials/nice/wytyczne/wytyczne_fizjoterapeuta.jpg';
import WytycznePacjent from '../../../../assets/materials/nice/wytyczne/wytyczne_pacjent.jpg';
import { HeaderSimple } from '../../../../components/HeaderSimple';
import { HeaderWithTriangles } from '../../../../components/HeaderWithTriangles';
import { customButtonThemeExtraBig } from '../../../../styles/buttons.mui';
import globalStyles from '../../../../styles/global.module.scss';
import styles from './Nice.module.scss';

const Nice = () => {
  // const [cookies] = useCookies(['phisioKnowledge']);

  const modifier = 'main';
  const title = 'NICE';

  return (
    <>
      <Helmet>
        <title>NICE - Projekt Infodemia KIF</title>
      </Helmet>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <div
          className={`${globalStyles.container} ${styles.container}`}
          data-app-type={modifier}
        >
          <HeaderWithTriangles title={title} className={styles.header} />
          <div
            className={styles.content}
            style={{ maxWidth: '900px', margin: 'auto', textAlign: 'center' }}
          >
            <p>
              <span style={{ fontStyle: 'italic' }}>
                {' '}
                National Institute for Health and Care Excellence (NICE)
              </span>
              <br />
              <br />
              to niezależna organizacja, która dostarcza wytyczne i porady
              mające na celu poprawę opieki zdrowotnej i społecznej w Wielkiej
              Brytanii. Misja NICE polega na zapewnianiu opartej na dowodach
              wiedzy i wsparcia w podejmowaniu decyzji związanych z opieką
              zdrowotną.
              <br />
            </p>
          </div>
          <HeaderWithTriangles
            title="Broszury NICE"
            className={styles.header}
          />
          <div className={styles.wytyczneTopWrap} style={{}}>
            <div style={{ position: 'relative' }}>
              <a href="/view-pdf/broszura_nice_fizjoterapeuta">
                <HeaderSimple color="#5294A3" title="DLA FIZJOTERAPEUTÓW" />
                <img
                  alt="Broszura Nice dla Fizjoterapeutów"
                  src={WytyczneFizjoterapeuta}
                  style={{ width: '100%' }}
                />
              </a>
            </div>
            <a href="/view-pdf/broszura_nice_pacjent">
              <HeaderSimple color="#C28781" title="DLA PACJENTÓW" />
              <img
                alt="Broszura Nice dla Pacjentów"
                src={WytycznePacjent}
                style={{ width: '100%' }}
              />
            </a>
          </div>
          <HeaderWithTriangles
            title="Wytyczne dla Fizjoterapeutów"
            className={styles.header}
          />
          <div style={{ position: 'relative' }}>
            {/* {!cookies.phisioKnowledge && <ShowCookieAlert />} */}
            <div className={styles.horizontalDocument}>
              <div className={styles.horizontalDocumentLeft}>
                <TaskOutlined sx={{ fontSize: '50px' }} />
              </div>
              <div className={styles.horizontalDocumentRight}>
                <h2>
                  Wytyczne postępowania u osób z długoterminowymi skutkami
                  COVID-19 dla fizjoterapeutów
                </h2>
                Adaptacja wytycznych postępowania National Institute for Health
                and Care Excellence{' '}
                <a
                  href="/view-pdf/nice-wytyczne"
                  className={styles.horizontalDocumentLink}
                >
                  Zapoznaj się
                </a>
              </div>
            </div>
            <br />

            <div className={styles.horizontalDocument}>
              <div className={styles.horizontalDocumentLeft}>
                <TaskOutlined sx={{ fontSize: '50px' }} />
              </div>
              <div className={styles.horizontalDocumentRight}>
                <h2>
                  Suplement 1 – Szczegółowe informacje dla poszczególnych pytań
                </h2>
                Wytyczne postępowania u osób z długoterminowymi skutkami
                COVID-19 dla fizjoterapeutów{' '}
                <a
                  href="/view-pdf/nice-wytyczne-suplement"
                  className={styles.horizontalDocumentLink}
                >
                  Zapoznaj się
                </a>
              </div>
            </div>
            <br />
            <div className={styles.horizontalDocument}>
              <div className={styles.horizontalDocumentLeft}>
                <TaskOutlined sx={{ fontSize: '50px' }} />
              </div>
              <div className={styles.horizontalDocumentRight}>
                <h2>Dokumenty dodatkowe</h2>
                Wytyczne postępowania u osób z długoterminowymi skutkami
                COVID-19 dla fizjoterapeutów{' '}
                <a
                  href="/view-pdf/nice-wytyczne-dokumenty-dodatkowe"
                  className={styles.horizontalDocumentLink}
                >
                  Zapoznaj się
                </a>
              </div>
            </div>
          </div>{' '}
          <HeaderWithTriangles title="Infografiki" className={styles.header} />
          <div className={styles.wytyczneTopWrap}>
            <div className={styles.flex50} style={{ position: 'relative' }}>
              {/* {!cookies.phisioKnowledge && <ShowCookieAlert />} */}
              <HeaderSimple color="#0D4D8B" title="DLA FIZJOTERAPEUTY" />
              <div className={styles.bigButtonsWrapper}>
                <ThemeProvider theme={customButtonThemeExtraBig}>
                  <Button
                    variant="contained"
                    component={Link}
                    to="/infographics/dobre_praktyki"
                  >
                    DOBRE PRAKTYKI
                  </Button>
                </ThemeProvider>
                <ThemeProvider theme={customButtonThemeExtraBig}>
                  <Button
                    variant="contained"
                    component={Link}
                    to="/infographics/warunkowe_zalecenia"
                  >
                    WARUNKOWE ZALECENIA
                  </Button>
                </ThemeProvider>
                <ThemeProvider theme={customButtonThemeExtraBig}>
                  <Button
                    variant="contained"
                    component={Link}
                    to="/infographics/zalecenia_za_interwencja"
                  >
                    ZALECENIA ZA INTERWENCJĄ
                  </Button>
                </ThemeProvider>
              </div>
            </div>
            <div className={styles.flex50}>
              <HeaderSimple color="#0D4D8B" title="DLA PACJENTA" />
              <div className={styles.bigButtonsWrapper}>
                <ThemeProvider theme={customButtonThemeExtraBig}>
                  <Button
                    variant="contained"
                    component={Link}
                    to="/infographics/badania_i_skierowania"
                  >
                    BADANIA I SKIEROWANIA
                  </Button>
                </ThemeProvider>
                <ThemeProvider theme={customButtonThemeExtraBig}>
                  <Button
                    variant="contained"
                    component={Link}
                    to="/infographics/diagnostyka_zespolu_post_covid"
                  >
                    DIAGNOSTYKA ZESPOŁU POST-COVID-19
                  </Button>
                </ThemeProvider>
                <ThemeProvider theme={customButtonThemeExtraBig}>
                  <Button
                    variant="contained"
                    component={Link}
                    to="/infographics/dzialania_nastepne"
                  >
                    DZIAŁANIA NASTĘPNE
                  </Button>
                </ThemeProvider>
                <ThemeProvider theme={customButtonThemeExtraBig}>
                  <Button
                    variant="contained"
                    component={Link}
                    to="/infographics/identyfikacja_schorzenia"
                  >
                    IDENTYFIKACJA SCHORZENIA
                  </Button>
                </ThemeProvider>
                <ThemeProvider theme={customButtonThemeExtraBig}>
                  <Button
                    variant="contained"
                    component={Link}
                    to="/infographics/opieka"
                  >
                    OPIEKA
                  </Button>
                </ThemeProvider>
                <ThemeProvider theme={customButtonThemeExtraBig}>
                  <Button
                    variant="contained"
                    component={Link}
                    to="/infographics/pytania_wyroznione"
                  >
                    PYTANIA WYROZNIONE PRZEZ KIF
                  </Button>
                </ThemeProvider>
              </div>
            </div>
          </div>
          <HeaderWithTriangles
            title="Prezentacja dla pacjenta"
            className={styles.header}
          />
          <a
            href="https://kizbafiz-my.sharepoint.com/:p:/g/personal/p101043_kif_info_pl/Ef6BS4j1qaZFpHTK8ZkSL3EBLH1vgZ3VIYCgng6aAGvDyQ?e=mDIkkj"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={Prezentacja}
              style={{ width: '100%' }}
              alt="Prezentacja dla pacjenta"
            />
          </a>
        </div>
      </CookiesProvider>
    </>
  );
};

export default Nice;
