import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Article } from '../components';
import { CustomLoading } from '../components/CustomLoading';
import { Infopage, Main } from '../domains';
import {
  Author,
  Authors,
  Cochrane,
  Ebm,
  Education,
  Infographic,
  Login,
  Nice,
  ViewPdf,
  Who,
} from '../domains/Main/components';
import { ArticlesList } from '../domains/Main/components/ArticlesList';
import { Path } from './paths';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Admin = lazy(() => import('../domains/Admin'));

const MainRouter = () => {
  const createRoutes = () => {
    return (
      <>
        <Route path={Path.ROOT} element={<PublicRoute component={Main} />} />
        <Route path={Path.LOGIN} element={<PublicRoute component={Login} />} />
        <Route
          path={Path.ARTICLES}
          element={<PublicRoute component={ArticlesList} />}
        />
        <Route
          path={`${Path.ARTICLE}/:id`}
          element={<PublicRoute component={Article} />}
        />
        <Route
          path={`${Path.VIEWPDF}/:pdfname`}
          element={<PublicRoute component={ViewPdf} />}
        />
        <Route
          path={`${Path.INFOGRAPHICS}/:category`}
          element={<PublicRoute component={Infographic} />}
        />
        <Route
          path={`${Path.ARTICLE_EMBED}/:id`}
          element={<PublicRoute component={Article} />}
        />
        <Route
          path={Path.AUTHORS}
          element={<PublicRoute component={Authors} />}
        />
        <Route
          path={`${Path.AUTHOR}/:id`}
          element={<PublicRoute component={Author} />}
        />
        <Route
          path={Path.EDUCATION}
          element={<PublicRoute component={Education} />}
        />
        <Route path={Path.WHO} element={<PublicRoute component={Who} />} />
        <Route
          path={Path.NICE}
          element={<PublicRoute component={Nice} />}
        />{' '}
        <Route
          path={Path.COCHRANE}
          element={<PublicRoute component={Cochrane} />}
        />{' '}
        <Route path={Path.EBM} element={<PublicRoute component={Ebm} />} />
        <Route
          path={Path.INFOPAGE}
          element={<PublicRoute component={Infopage} />}
        />
        <Route
          path={`${Path.ADMIN}/*`}
          element={<PrivateRoute component={Admin} />}
        />
        <Route path="*" element={<Navigate to={`${Path.ROOT}`} replace />} />
      </>
    );
  };

  return (
    <Suspense fallback={<CustomLoading />}>
      <Routes>{createRoutes()}</Routes>
    </Suspense>
  );
};

export default MainRouter;
