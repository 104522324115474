/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, ThemeProvider } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { login } from '../../../../api/services/auth';
import { CustomTextField } from '../../../../components';
import { CustomLoading } from '../../../../components/CustomLoading';
import { Path } from '../../../../router/paths';
import { customButtonTheme } from '../../../../styles/buttons.mui';
import globalStyles from '../../../../styles/global.module.scss';
import styles from './Login.module.scss';

const Login = () => {
  const navigate = useNavigate();

  const { isLoading, mutate } = useMutation({
    mutationKey: ['login'],
    mutationFn: login,
    onSuccess: () => {
      navigate(Path.ADMIN);
    },
  });

  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    onSubmit: (values) => {
      mutate(values);
    },
  });

  if (isLoading) {
    return <CustomLoading />;
  }

  return (
    <div className={`${globalStyles.container} ${styles.container}`}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.form}>
          <CustomTextField
            id="login"
            label="Login"
            variant="outlined"
            sx={{ margin: '0 0 10px 0' }}
            onChange={formik.handleChange}
            value={formik.values.login}
          />
          <CustomTextField
            id="password"
            label="Hasło"
            variant="outlined"
            type="password"
            sx={{ margin: '0 0 10px 0' }}
            onChange={formik.handleChange}
            value={formik.values.password}
          />
          <ThemeProvider theme={customButtonTheme}>
            <Button variant="contained" type="submit">
              Zaloguj
            </Button>
          </ThemeProvider>
          {/* <div className={styles.registerBox}>
            Nie masz konta? <Link to={Path.REGISTER}>Zarejestruj się.</Link>
          </div> */}
        </div>
      </form>
    </div>
  );
};

export default Login;
