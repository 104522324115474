import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import fuzzysort from 'fuzzysort';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';

import { ArticleCategoryDto } from '../../../../api/models/ArticleCategoryDto';
import {
  ArticleDto,
  ArticleTarget,
  ArticleTargetText,
} from '../../../../api/models/ArticleDto';
import { UserDto } from '../../../../api/models/UserDto';
import {
  getArticleCategories,
  getArticles,
} from '../../../../api/services/article';
import { getAuthors } from '../../../../api/services/user';
import {
  ArticleContainer,
  ArticleItem,
} from '../../../../components/ArticleItem';
import { CustomLoading } from '../../../../components/CustomLoading';
import { SEARCH_PARAM_NAME } from '../../../../components/SearchBar/SearchBar';
import { Path } from '../../../../router/paths';
import globalStyles from '../../../../styles/global.module.scss';
import styles from './ArticlesList.module.scss';

/**
 * paginacja tablicy
 * @param array tablica
 * @param pageSize ile elementow na stronie
 * @param pageNumber aktualna strona (zaczyna sie od 1)
 * @returns tablice elementow dla aktualnej strony
 */
const paginate = (array: any[], pageSize: number, pageNumber: number) => {
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};

const resultsMap = new Map();
const ArticlesList = () => {
  const itemsPerPage = 6;
  const sortByOptions = [
    { value: 1, name: 'Nazwa - rosnąco' },
    { value: 2, name: 'Nazwa - malejąco' },
    { value: 3, name: 'Data dodania - od najstaszych' },
    { value: 4, name: 'Data dodania - od najnowszych' },
    { value: 5, name: 'Kategoria - rosnąco' },
    { value: 6, name: 'Kategoria - malejąco' },
  ];

  const [type, setType] = useState('');
  const [category, setCategory] = useState('');
  const [author, setAuthor] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [page, setPage] = useState(1);
  const [allPages, setAllPage] = useState(0);

  const handleChange = (event: SelectChangeEvent, setter: any) => {
    setter(event.target.value);
  };

  const [articleCategories, setArticleCategories] = useState<
    ArticleCategoryDto[]
  >([]);

  const {
    isLoading: isArticleCategoriesLoading,
    error: articleCategoriesError,
    data: articleCategoriesData,
  } = useQuery<ArticleCategoryDto[], AxiosError>({
    queryKey: ['articleCategories'],
    queryFn: () => getArticleCategories().then((res) => res.data),
  });

  useEffect(() => {
    if (articleCategoriesData) {
      setArticleCategories(articleCategoriesData);
    }
  }, [articleCategoriesData]);

  const [articleAuthors, setArticleAuthors] = useState<UserDto[]>([]);

  const {
    isLoading: isArticleAuthorsLoading,
    error: articleAuthorsError,
    data: articleAuthorsData,
  } = useQuery<UserDto[], AxiosError>({
    queryKey: ['articleAuthors'],
    queryFn: () => getAuthors().then((res) => res.data),
  });

  useEffect(() => {
    if (articleAuthorsData) {
      setArticleAuthors(articleAuthorsData);
    }
  }, [articleAuthorsData]);

  const [articles, setArticles] = useState<ArticleDto[]>([]);

  const {
    isLoading: isArticlesLoading,
    error: articlesError,
    data: articlesData,
  } = useQuery<ArticleDto[], AxiosError>({
    queryKey: ['articles'],
    queryFn: () => getArticles().then((res) => res.data),
  });

  const [searchParams] = useSearchParams();
  const searchText = searchParams.get(SEARCH_PARAM_NAME);
  const [filteredArticles, setFilteredArticles] = useState<ArticleDto[]>([]);
  useEffect(() => {
    if (!articlesData) {
      return undefined;
    }

    let filtered = [...articlesData];
    if (type !== '') {
      filtered = filtered.filter(
        (article) => `${article.target}` === `${type}`
      );
    }

    if (category !== '') {
      filtered = filtered.filter(
        (article) => `${article.category.id}` === `${category}`
      );
    }

    if (author !== '') {
      filtered = filtered.filter(
        (article) =>
          `${article.user.id}` === `${author}` ||
          article.authors.find((a) => `${a.id}` === `${author}`)
      );
    }

    if (searchText) {
      const results = fuzzysort.go(searchText, filtered, {
        keys: ['title', 'lead'],
        threshold: -100000,
      });

      resultsMap.clear();
      filtered = results.map((result) => {
        resultsMap.set(result.obj, result);
        return result.obj;
      });
    }

    if (sortBy !== '') {
      filtered = [...filtered].sort((a, b) => {
        switch (`${sortBy}`) {
          case '1':
            return a.title.localeCompare(b.title, 'pl');
          case '2':
            return b.title.localeCompare(a.title, 'pl');
          case '3':
            return a && b
              ? a.update_time.getTime() - b.update_time.getTime()
              : 0;
          case '4':
            return a && b
              ? b.update_time.getTime() - a.update_time.getTime()
              : 0;
          case '5':
            return a.category.name.localeCompare(b.category.name, 'pl');
          case '6':
            return b.category.name.localeCompare(a.category.name, 'pl');
          default:
            return 0;
        }
      });
    }

    setPage(1);
    setFilteredArticles(filtered);
    setAllPage(Math.ceil(filtered.length / itemsPerPage));

    // TODO: do zastanowienia jak to jeszcze poprawic bo czasami stan jest zle ustawiany (przy back-forward nav)
    return () => {
      resultsMap.clear();
      setFilteredArticles([]);
    };
  }, [articlesData, searchText, type, category, author, sortBy]);

  useEffect(() => {
    const currentPage = paginate(filteredArticles, itemsPerPage, page);

    setArticles(currentPage);
  }, [`${filteredArticles.map((item) => item.id)}`, page]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  }, [page]);

  if (
    isArticlesLoading ||
    isArticleCategoriesLoading ||
    isArticleAuthorsLoading
  ) {
    return <CustomLoading />;
  }

  if (articlesError || articleCategoriesError || articleAuthorsError) {
    return (
      <div>
        Wystąpił błąd...{' '}
        {articlesError?.message ||
          articleCategoriesError?.message ||
          articleAuthorsError?.message}
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Publikacje - Projekt Infodemia KIF</title>
      </Helmet>
      <div className={styles.filters}>
        <FormControl variant="outlined" sx={{ minWidth: 180 }}>
          <InputLabel id="type-label">Rodzaj</InputLabel>
          <Select
            labelId="type-label"
            id="type"
            value={type}
            onChange={(e) => handleChange(e, setType)}
          >
            <MenuItem value="">
              <em>wszystkie</em>
            </MenuItem>
            <MenuItem value={ArticleTarget.Patient}>Pacjent</MenuItem>
            <MenuItem value={ArticleTarget.Physiotherapist}>
              Fizjoterapeuta
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="outlined" sx={{ minWidth: 180 }}>
          <InputLabel id="type-category">Kategoria</InputLabel>
          <Select
            labelId="type-category"
            id="category"
            value={category}
            onChange={(e) => handleChange(e, setCategory)}
          >
            <MenuItem value="">
              <em>wszystkie</em>
            </MenuItem>
            {articleCategories.map((cat) => (
              <MenuItem key={cat.id} value={cat.id}>
                {cat.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" sx={{ minWidth: 180 }}>
          <InputLabel id="type-authors">Autorzy</InputLabel>
          <Select
            labelId="type-authors"
            id="authors"
            value={author}
            onChange={(e) => handleChange(e, setAuthor)}
          >
            <MenuItem value="">
              <em>wszyscy</em>
            </MenuItem>
            {articleAuthors.map(
              (item) =>
                item.id !== 18 &&
                item.id !== 19 &&
                item.id !== 1 && (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                )
            )}
          </Select>
        </FormControl>

        <FormControl variant="outlined" sx={{ minWidth: 180 }}>
          <InputLabel id="sort-label">Sortowanie</InputLabel>
          <Select
            labelId="sort-label"
            id="sort"
            value={sortBy}
            onChange={(e) => handleChange(e, setSortBy)}
          >
            <MenuItem value="">
              <em>domyślne</em>
            </MenuItem>
            {sortByOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className={`${globalStyles.container} ${styles.container}`}>
        <ArticleContainer>
          {articles.length > 0 ? (
            articles.map((article) => {
              const results = resultsMap.get(article);

              const Title =
                results && results[0] ? (
                  <>
                    {fuzzysort.highlight(results[0], (m, i) => (
                      <mark key={i}>{m}</mark>
                    ))}
                  </>
                ) : (
                  article.title
                );

              const Lead =
                results && results[1] ? (
                  <>
                    {fuzzysort.highlight(results[1], (m, i) => (
                      <mark key={i}>{m}</mark>
                    ))}
                  </>
                ) : (
                  article.lead
                );

              const authors = [article.user, ...article.authors].filter(
                (value, index, self) => {
                  return self.findIndex((a) => a.id === value.id) === index;
                }
              );

              return (
                <ArticleItem
                  key={article.id}
                  category={`${ArticleTargetText[article.target]} / ${
                    article.category.name
                  }`}
                  date={article.update_time.toLocaleDateString('pl-PL')}
                  href={`${Path.ARTICLE}/${article.id}`}
                  title={Title}
                  lead={Lead}
                  authors={authors}
                />
              );
            })
          ) : (
            <div style={{ margin: '0 auto' }}>
              Nie znaleziono artykułów pasujących do aktualnego filtrowania.
            </div>
          )}
        </ArticleContainer>
      </div>
      {allPages > 1 && (
        <div
          className={`${globalStyles.container} ${styles.container} ${styles.pagination}`}
        >
          <Stack spacing={2}>
            <Pagination
              count={allPages}
              color="primary"
              page={page}
              onChange={(e: any, value: number) => setPage(value)}
            />
          </Stack>
        </div>
      )}
    </>
  );
};

export default ArticlesList;
