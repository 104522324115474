/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable global-require */
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';

import { Button } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  Animacja1,
  Animacja2,
  Animacja3,
  Animacja4,
} from '../../../../assets/materials/edukacyjne/animacje';
import JakWalczyc from '../../../../assets/materials/edukacyjne/dokumenty/jak_walczyc.jpg';
import TypySzczepionek from '../../../../assets/materials/edukacyjne/dokumenty/typy_szczepionek.jpg';
import video1 from '../../../../assets/materials/edukacyjne/podcasty/podcast_fizjo_1.mp4';
import video2 from '../../../../assets/materials/edukacyjne/podcasty/podcast_fizjo_2.mp4';
import video3 from '../../../../assets/materials/edukacyjne/podcasty/podcast_fizjo_3.mp4';
import video4 from '../../../../assets/materials/edukacyjne/podcasty/podcast_pacjent_1.mp4';
import video5 from '../../../../assets/materials/edukacyjne/podcasty/podcast_pacjent_2.mp4';
import video6 from '../../../../assets/materials/edukacyjne/podcasty/podcast_pacjent_3.mp4';
import { HeaderSimple } from '../../../../components/HeaderSimple';
import { HeaderWithTriangles } from '../../../../components/HeaderWithTriangles';
import { customButtonThemeExtraBig } from '../../../../styles/buttons.mui';
import globalStyles from '../../../../styles/global.module.scss';
import styles from './Education.module.scss';

const Education = () => {
  // const [cookies] = useCookies(['phisioKnowledge']);

  const modifier = 'main';
  const title = 'Materiały edukacyjne';
  const [animationId, setAnimationId] = useState(Animacja1);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  useEffect(() => {
    videoRef.current?.load();
  }, [animationId]);
  const myId = window.location.hash.slice(1);

  useEffect(() => {
    document.getElementById(myId)?.scrollIntoView();
  }, [myId]);

  function myRandomInts(quantity: number, max: number) {
    const arr = [];
    while (arr.length < quantity) {
      const candidateInt = Math.floor(Math.random() * max) + 1;
      if (arr.indexOf(candidateInt) === -1) arr.push(candidateInt + 1);
    }
    return arr;
  }
  const slides = myRandomInts(8, 53);

  return (
    <>
      <Helmet>
        <title>Materiały edukacyjne - Projekt Infodemia KIF</title>
      </Helmet>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <div
          className={`${globalStyles.container} ${styles.container}`}
          data-app-type={modifier}
        >
          <HeaderWithTriangles title={title} className={styles.header} />

          <div
            className={styles.content}
            style={{
              marginBottom: '50px',
              maxWidth: '900px',
              margin: 'auto',
              textAlign: 'center',
            }}
          >
            <p style={{ fontSize: '1.3rem' }}>
              W tej sekcji odnajdziecie Państwo materiały graficzne, które mogą
              pomóc podczas podejmowanych rozmów na tematy medyczne.
              <br />
              <br />
            </p>
          </div>
          <div id="infografiki" key="infografiki" />
          <Swiper
            className={styles.slider}
            loop
            autoplay={{
              pauseOnMouseEnter: true,
            }}
            navigation
            modules={[Navigation, Autoplay]}
          >
            {slides.map((slide) => (
              <SwiperSlide className={styles.slide} key={slide}>
                <figure className={styles.figure}>
                  <img
                    className={styles.img}
                    // eslint-disable-next-line import/no-dynamic-require
                    src={require(`../../../../assets/materials/edukacyjne/fakty_i_mity/fakty_i_mity_${slide}.jpeg`)}
                    alt=""
                  />
                </figure>
              </SwiperSlide>
            ))}
          </Swiper>
          <div id="podcasty" key="podcasty" />
          <HeaderWithTriangles title="Podcasty" className={styles.header} />
          <div style={{ position: 'relative' }}>
            {/* {!cookies.phisioKnowledge && <ShowCookieAlert />} */}

            <HeaderSimple color="#f03f61" title="DLA FIZJOTERAPEUTÓW" />
            <div className={styles.podcastsWrapper}>
              <div className={styles.podcastBox}>
                <video
                  width="100%"
                  height="auto"
                  autoPlay={false}
                  controls
                  loop
                >
                  <source src={video1} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className={styles.podcastTitle}>Czym jest Infodemia?</div>
              </div>
              <div className={styles.podcastBox}>
                <video
                  width="100%"
                  height="auto"
                  autoPlay={false}
                  controls
                  loop
                >
                  <source src={video2} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className={styles.podcastTitle}>
                  Jak walczyć z Infodemią?
                </div>
              </div>
              <div className={styles.podcastBox}>
                <video width="100%" height="auto" controls loop muted>
                  <source src={video3} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className={styles.podcastTitle}>
                  O szczepionkach. mity i fakty dotyczące szczepionek na
                  COVID-19
                </div>
              </div>
            </div>
          </div>
          <HeaderSimple color="#f03f61" title="DLA PACJENTÓW" />
          <div className={styles.podcastsWrapper}>
            <div className={styles.podcastBox}>
              <video
                width="100%"
                height="auto"
                autoPlay={false}
                controls
                loop
                key="4"
              >
                <source src={video4} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className={styles.podcastTitle}>Czym jest Infodemia?</div>
            </div>
            <div className={styles.podcastBox}>
              <video
                width="100%"
                height="auto"
                autoPlay={false}
                controls
                loop
                key="5"
              >
                <source src={video5} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className={styles.podcastTitle}>
                Jak walczyć z Infodemią?
              </div>
            </div>
            <div className={styles.podcastBox}>
              <video width="100%" height="auto" controls loop key="6">
                <source src={video6} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className={styles.podcastTitle}>
                O szczepionkach. mity i fakty dotyczące szczepionek na COVID-19
              </div>
            </div>
          </div>
          <div id="dokumenty" key="dokumenty" />
          <HeaderWithTriangles title="Dokumenty" className={styles.header} />

          <div className={styles.flexWrap} style={{ marginBottom: '50px' }}>
            <div className={styles.flex50}>
              <div className={styles.horizontalDocument}>
                <div className={styles.horizontalDocumentLeft}>
                  <img src={JakWalczyc} alt="" />
                </div>
                <div className={styles.horizontalDocumentRight}>
                  <h3>Jak walczyć z Infodemią? Wszystko o szczepionkach </h3>
                  <a
                    href="/view-pdf/jak-walczyc-z-infodemia"
                    className={styles.horizontalDocumentLink}
                  >
                    Zapoznaj się
                  </a>
                </div>
              </div>
            </div>
            <div className={styles.flex50}>
              <div className={styles.horizontalDocument}>
                <div className={styles.horizontalDocumentLeft}>
                  <img src={TypySzczepionek} alt="" />
                </div>
                <div className={styles.horizontalDocumentRight}>
                  <h3>Jakie są typy szczepionek i jak są one wytwarzane?</h3>
                  <a
                    href="/view-pdf/typy-szczepionek"
                    className={styles.horizontalDocumentLink}
                  >
                    Zapoznaj się
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div id="filmy" key="filmy" />

          <video
            width="100%"
            height="auto"
            autoPlay
            controls
            muted
            loop
            ref={videoRef}
            key="532"
          >
            <source src={animationId} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div
            className={styles.animationsWrapper}
            style={{ display: 'flex', gap: '10px', marginTop: '20px' }}
          >
            <ThemeProvider theme={customButtonThemeExtraBig}>
              <Button
                variant="contained"
                onClick={() => setAnimationId(Animacja1)}
              >
                1
              </Button>
              <Button
                variant="contained"
                onClick={() => setAnimationId(Animacja2)}
              >
                2
              </Button>
              <Button
                variant="contained"
                onClick={() => setAnimationId(Animacja3)}
              >
                3
              </Button>
              <Button
                variant="contained"
                onClick={() => setAnimationId(Animacja4)}
              >
                4
              </Button>
            </ThemeProvider>
          </div>
        </div>
      </CookiesProvider>
    </>
  );
};

export default Education;
