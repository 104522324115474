import { useState } from 'react';
import { slide as Menu, State } from 'react-burger-menu';
import { NavLink } from 'react-router-dom';

import { SearchBar } from '../SearchBar';
import { navItems } from './config';
import styles from './Navigation.module.scss';

const burgerStyles = {
  bmBurgerButton: {
    position: 'absolute',
    width: '36px',
    height: '30px',
    right: '36px',
    top: '36px',
  },
  bmBurgerBars: {
    background: '#373a47',
  },
  bmBurgerBarsHover: {
    background: '#a90000',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: '0',
    right: '0',
    left: '0',
    width: 'auto',
  },
  bmMenu: {
    background: '#fff',
    padding: '20px',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em',
  },
  bmItem: {
    display: 'inline-block',
    width: '100%',
    textDecoration: 'none',
    padding: '5px 0',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onCloseMenu = () => setIsMenuOpen(false);
  const handleStateChange = (state: State) => {
    setIsMenuOpen(state.isOpen);
  };
  return (
    <div className={styles.box}>
      <SearchBar />
      <div className={styles.navItems}>
        {navItems.map((item) => {
          // const match = !!matchPath(location.pathname, item.path);

          return (
            <NavLink
              to={item.path}
              className={styles.navLink}
              key={item.path}
              target="_self"
            >
              {item.icon ? (
                <img src={item.icon} alt="" className={styles.icon} />
              ) : null}
              <div className={styles.label}>{item.label}</div>
            </NavLink>
          );
        })}
      </div>
      <div className={styles.navMobileItems}>
        <Menu
          width={380}
          styles={burgerStyles}
          isOpen={isMenuOpen}
          onStateChange={handleStateChange}
        >
          {navItems.map((item) => {
            return (
              <NavLink
                to={item.path}
                className={styles.navLinkMobile}
                key={item.path}
                target="_self"
                onClick={onCloseMenu}
              >
                {item.icon ? (
                  <img src={item.icon} alt="" className={styles.icon} />
                ) : null}
                <div className={styles.label}>{item.label}</div>
              </NavLink>
            );
          })}
        </Menu>
      </div>
    </div>
  );
};

export default Navigation;
