import { TaskOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import {
  Animacja1,
  Animacja2,
  Animacja3,
  Animacja4,
  Animacja5,
} from '../../../../assets/materials/who/animacje';
/* eslint-disable jsx-a11y/media-has-caption */
import BroszuraFizjoterapeuta from '../../../../assets/materials/who/broszury/who_broszura_fizjoterapeuta.jpg';
import BroszuraPacjent from '../../../../assets/materials/who/broszury/who_broszura_pacjenci.jpg';
import Prezentacja from '../../../../assets/materials/who/who_prezentacja.jpg';
import { ShowCookieAlert } from '../../../../components';
import { HeaderWithTriangles } from '../../../../components/HeaderWithTriangles';
import {
  customButtonThemeExtraBig,
  customButtonThemeExtraBigFull,
} from '../../../../styles/buttons.mui';
import globalStyles from '../../../../styles/global.module.scss';
import styles from './Who.module.scss';

const Who = () => {
  const [cookies] = useCookies(['phisioKnowledge']);

  const modifier = 'main';
  const title = 'WHO';
  const [animationId, setAnimationId] = useState(Animacja1);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    videoRef.current?.load();
  }, [animationId]);
  return (
    <>
      <Helmet>
        <title>WHO - Projekt Infodemia KIF</title>
      </Helmet>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <div
          className={`${globalStyles.container} ${styles.container}`}
          data-app-type={modifier}
        >
          <HeaderWithTriangles title={title} className={styles.header} />
          <div
            className={styles.content}
            style={{ maxWidth: '900px', margin: 'auto', textAlign: 'center' }}
          >
            <p>
              Światowa Organizacja Zdrowia działa w ramach systemu Narodów
              Zjednoczonych i Jest odpowiedzialna za najważniejsze kwestie w
              ochronie zdrowia,
              <br />
            </p>
          </div>
          <HeaderWithTriangles title="Broszury" className={styles.header} />
          <div className={styles.broszuryWrap}>
            <div className={styles.broszura}>
              <div style={{ position: 'relative' }}>
                {/* {!cookies.phisioKnowledge && <ShowCookieAlert />} */}
                <img
                  src={BroszuraFizjoterapeuta}
                  alt="Broszura dla fizjoterapeuty"
                  style={{ width: '100%', border: '1px solid #164C8A' }}
                />

                <ThemeProvider theme={customButtonThemeExtraBigFull}>
                  <Button
                    variant="contained"
                    fullWidth
                    component={Link}
                    to="/view-pdf/who-broszura-fizjoterapeuta"
                  >
                    DLA FIZJOTERAPEUTY
                  </Button>
                </ThemeProvider>
              </div>
            </div>
            <div className={styles.broszura}>
              <img
                src={BroszuraPacjent}
                alt="Broszura dla pacjenta"
                style={{ width: '100%', border: '1px solid #164C8A' }}
              />
              <ThemeProvider theme={customButtonThemeExtraBigFull}>
                <Button
                  variant="contained"
                  fullWidth
                  component={Link}
                  to="/view-pdf/who-broszura-pacjent"
                >
                  DLA PACJENTA
                </Button>
              </ThemeProvider>
            </div>
          </div>
          <HeaderWithTriangles
            title="Wytyczne dla Fizjoterapeutów"
            className={styles.header}
          />
          <div style={{ position: 'relative' }}>
            {/* {!cookies.phisioKnowledge && <ShowCookieAlert />} */}
            <div className={styles.horizontalDocument}>
              <div className={styles.horizontalDocumentLeft}>
                <TaskOutlined sx={{ fontSize: '50px' }} />
              </div>
              <div className={styles.horizontalDocumentRight}>
                <h2>
                  Wytyczne zapobiegania i zwalczania zakażeń w kontekście
                  COVID-19 dla fizjoterapeutów
                </h2>
                Adaptacja wytycznych postępowania Światowej Organizacji Zdrowia
                <a
                  href="/view-pdf/who-wytyczne"
                  className={styles.horizontalDocumentLink}
                >
                  Zapoznaj się
                </a>
              </div>
            </div>
          </div>{' '}
          <HeaderWithTriangles
            title="Prezentacja dla Fizjoterapeutów"
            className={styles.header}
          />{' '}
          <div style={{ position: 'relative' }}>
            {!cookies.phisioKnowledge && <ShowCookieAlert />}
            <a
              href="https://kizbafiz-my.sharepoint.com/:p:/g/personal/p101043_kif_info_pl/Een3W5PteYxNg9CLNnXV-oIBEGoreQQd3hP2chBPY3xpGw"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={Prezentacja}
                alt="Prezentacja"
                style={{ width: '100%' }}
              />
            </a>
          </div>
          <HeaderWithTriangles title="Animacje" className={styles.header} />
          <video
            width="100%"
            height="auto"
            autoPlay
            controls
            muted
            loop
            ref={videoRef}
          >
            <source src={animationId} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div
            className={styles.animationsWrapper}
            style={{ display: 'flex', gap: '10px', marginTop: '20px' }}
          >
            <ThemeProvider theme={customButtonThemeExtraBig}>
              <Button
                variant="contained"
                onClick={() => setAnimationId(Animacja1)}
              >
                1
              </Button>
              <Button
                variant="contained"
                onClick={() => setAnimationId(Animacja2)}
              >
                2
              </Button>
              <Button
                variant="contained"
                onClick={() => setAnimationId(Animacja3)}
              >
                3
              </Button>
              <Button
                variant="contained"
                onClick={() => setAnimationId(Animacja4)}
              >
                4
              </Button>
              <Button
                variant="contained"
                onClick={() => setAnimationId(Animacja5)}
              >
                {' '}
                5
              </Button>
            </ThemeProvider>
          </div>
        </div>
      </CookiesProvider>
    </>
  );
};

export default Who;
