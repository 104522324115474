/* eslint-disable global-require */
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { HeaderWithTriangles } from '../../../../components/HeaderWithTriangles';
import globalStyles from '../../../../styles/global.module.scss';
import styles from './Infographic.module.scss';

/* eslint-disable-next-line simple-import-sort/imports */

const Infographic = () => {
  // const { hash } = useLocation();
  const { category: paramCategory } = useParams();
  const category = paramCategory;
  let files = 0;
  let title = 'Infografiki';
  if (category && category === 'dobre_praktyki') {
    files = 9;
    title = 'Infografiki - dobre praktyki';
  }
  if (category && category === 'warunkowe_zalecenia') {
    files = 7;
    title = 'Infografiki - warunkowe zalecenia';
  }
  if (category && category === 'zalecenia_za_interwencja') {
    files = 1;
    title = 'Infografiki - zalecenia za interwencją';
  }
  if (category && category === 'badania_i_skierowania') {
    files = 9;
    title = 'Infografiki - badania i skierowania';
  }
  if (category && category === 'diagnostyka_zespolu_post_covid') {
    files = 5;
    title = 'Infografiki - diagnostyka zespołu post-covid-19';
  }
  if (category && category === 'dzialania_nastepne') {
    files = 3;
    title = 'Infografiki - działania następne';
  }
  if (category && category === 'identyfikacja_schorzenia') {
    files = 6;
    title = 'Infografiki - identyfikacja schorzenia';
  }
  if (category && category === 'opieka') {
    files = 7;
    title = 'Infografiki - opieka';
  }
  if (category && category === 'planowanie_opieki') {
    files = 2;
    title = 'Infografiki - planowanie opieki';
  }
  if (category && category === 'pytania_wyroznione') {
    files = 4;
    title = 'Infografiki - pytania wyróżnione';
  }
  const images = [];
  for (let i = 1; i <= files; i += 1) {
    images.push(
      <img
        style={{ width: '100%', marginTop: '50px', maxWidth: '1000px' }}
        // eslint-disable-next-line import/no-dynamic-require
        src={require(`../../../../assets/materials/infographics/${category}/infografika_${i}.jpg`)}
        alt="Infografika"
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Infografiki - Projekt Infodemia KIF</title>
      </Helmet>
      <div
        className={`${globalStyles.container} ${styles.container}`}
        data-app-type="main"
      >
        {' '}
        <HeaderWithTriangles title={title} className={styles.header} />
        {images}
      </div>
    </>
  );
};

export default Infographic;
