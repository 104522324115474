import { AxiosPromise } from 'axios';

import axiosInstance from '../axiosInstance';
import { baseAPIUrl } from '../config';
import { ArticleAuthDto, CreateUpdateArticleDto } from '../models/ArticleDto';
import {
  AllArticlesTocAuthApiDto,
  ArticleTocApiDto,
  ArticleTocAuthDto,
} from '../models/ArticleTocDto';
import { LoginDto, LoginRequestDto } from '../models/LoginDto';
import { UserDto } from '../models/UserDto';

export const login = (data: LoginRequestDto): AxiosPromise<LoginDto> => {
  return axiosInstance({
    url: `${baseAPIUrl}/auth/login`,
    method: 'POST',
    data,
  }).then((response) => {
    if (response.data?.authToken) {
      localStorage.setItem('authToken', response.data.authToken);
    }
    return response;
  });
};

export const logout = () => {
  localStorage.removeItem('authToken');
  localStorage.removeItem('currentUser');
};

export const getProfile = (): AxiosPromise<UserDto> => {
  return axiosInstance({
    url: `${baseAPIUrl}/auth/profile`,
    method: 'GET',
  })
    .then((response) => {
      const { data } = response;
      localStorage.setItem('currentUser', JSON.stringify(data));
      return response;
    })
    .catch((error) => {
      localStorage.removeItem('currentUser');
      throw error;
    });
};

type CurrentUserDto = Pick<UserDto, 'id' | 'name' | 'avatar'>;
function isCurrentUserDto(user: CurrentUserDto | any): user is CurrentUserDto {
  return (
    (user as CurrentUserDto).id !== undefined &&
    (user as CurrentUserDto).name !== undefined &&
    (user as CurrentUserDto).avatar !== undefined
  );
}

export const getCurrentUser = () => {
  try {
    const currentUserJson = localStorage.getItem('currentUser');
    const currentUser = JSON.parse(currentUserJson!);
    return isCurrentUserDto(currentUser) ? currentUser : null;
  } catch (e) {
    return null;
  }
};

export const getAuthArticles = (): AxiosPromise<ArticleAuthDto[]> => {
  return axiosInstance({
    url: `${baseAPIUrl}/articles/auth`,
    method: 'GET',
  });
};

export const getAuthArticle = (id: number): AxiosPromise<ArticleAuthDto> => {
  return axiosInstance({
    url: `${baseAPIUrl}/articles/auth/${id}`,
    method: 'GET',
  });
};

export const postAuthArticle = (
  article: CreateUpdateArticleDto
): AxiosPromise<any> => {
  const { id, imageFile, ...data } = article;

  const formData = new FormData();
  if (imageFile) formData.append('imageFile', imageFile);
  formData.append('data', JSON.stringify(data));

  return axiosInstance({
    url: `${baseAPIUrl}/articles/auth${id ? `/${id}` : ''}`,
    method: id ? 'PUT' : 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteAuthArticle = (id: number): AxiosPromise<any> => {
  return axiosInstance({
    url: `${baseAPIUrl}/articles/auth/${id}`,
    method: 'DELETE',
  });
};

export const getAuthArticlesToc = (): AxiosPromise<
  AllArticlesTocAuthApiDto[]
> => {
  return axiosInstance({
    url: `${baseAPIUrl}/article-toc/auth`,
    method: 'GET',
  });
};
export const getAuthArticleToc = (
  id: number,
  tocId?: number
): AxiosPromise<ArticleTocAuthDto[]> => {
  return axiosInstance({
    url: `${baseAPIUrl}/article-toc/auth/${id}${tocId ? `/${tocId}` : ''}`,
    method: 'GET',
  });
};

export const postAuthArticleToc = (
  articleToc: ArticleTocApiDto
): AxiosPromise<any> => {
  const { id, ...data } = articleToc;

  return axiosInstance({
    url: `${baseAPIUrl}/article-toc/auth${id ? `/${id}` : ''}`,
    method: id ? 'PUT' : 'POST',
    data,
  });
};

export const setArticleTocEditor = (id: number): AxiosPromise<any> => {
  return axiosInstance({
    url: `${baseAPIUrl}/article-toc/auth/${id}/set-editor`,
    method: 'PATCH',
    data: {},
  });
};

export const unsetArticleTocEditor = (id: number): AxiosPromise<any> => {
  return axiosInstance({
    url: `${baseAPIUrl}/article-toc/auth/${id}/unset-editor`,
    method: 'PATCH',
    data: {},
  });
};

export const takeOverArticleTocEditor = (id: number): AxiosPromise<any> => {
  return axiosInstance({
    url: `${baseAPIUrl}/article-toc/auth/${id}/take-over-editor`,
    method: 'PATCH',
    data: {},
  });
};

export const deleteAuthArticleToc = (id: number): AxiosPromise<any> => {
  return axiosInstance({
    url: `${baseAPIUrl}/article-toc/auth/${id}`,
    method: 'DELETE',
  });
};

export type UpdateProfileDto = UserDto & {
  avatarFile?: File;
};

export const postAuthProfile = (user: UpdateProfileDto): AxiosPromise<any> => {
  const { id, ...data } = user;

  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (value != null) formData.append(key, value);
  });

  return axiosInstance({
    url: `${baseAPIUrl}/authors/auth`,
    method: 'PUT',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const postAuthPassword = (data: {
  password: string;
  newPassword: string;
  confirmPassword: string;
}): AxiosPromise<any> => {
  return axiosInstance({
    url: `${baseAPIUrl}/authors/password/auth`,
    method: 'PUT',
    data,
  });
};
