import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { AuthorDto } from '../../api/models/AuthorDto';
import { Avatar } from '../../assets/icons';
import styles from './ArticleItem.module.scss';

interface IArticleAvatarsProps {
  authors: AuthorDto[] | undefined;
}

const ArticleAvatars = ({ authors }: IArticleAvatarsProps) => {
  const arr = [];
  const max = 3;
  if (authors && authors.length > 0) {
    for (let i = 0; i < Math.min(authors.length, max); i += 1) {
      const author = authors[i];
      if (author.id !== 1) {
        arr.push(
          <img
            className={styles.articleAvatar}
            src={author.avatar || Avatar}
            alt=" "
            key={author.id}
          />
        );
        if (i === max - 1 && authors.length > max) {
          arr.push(
            <div
              className={[styles.articleAvatar, styles.articleAvatarMore].join(
                ' '
              )}
              key={0}
            >
              +{authors.length - max}
            </div>
          );
        }
      }
    }
  }
  return <div className={styles.articleAvatars}>{arr}</div>;
};

interface IArticleAuthorsProps {
  authors: AuthorDto[] | undefined;
  children: ReactNode;
}

const ArticleAuthors = ({ authors, children }: IArticleAuthorsProps) => {
  const arr = [];
  const max = 3;
  if (authors && authors.length > 0) {
    for (let i = 0; i < Math.min(authors.length, max); i += 1) {
      const author = authors[i];
      if (author.id !== 1) {
        arr.push(
          <div className={styles.articleAuthor} key={author.id}>
            {author.name}
            {i === max - 1 && authors.length > max && (
              <span
                title={authors
                  .slice(max)
                  .map((a) => a.name)
                  .join('\n')}
              >
                {' '}
                +{authors.length - max}
              </span>
            )}
          </div>
        );
      }
    }
  }
  return (
    <div className={styles.articleAuthors}>
      {arr}
      {children}
    </div>
  );
};

export enum EArticleItemType {
  DEFAULT,
  ADD,
  ADMIN,
}

export interface IProps {
  type?: EArticleItemType;
  children?: ReactNode;
  authors?: AuthorDto[];
  authorship?: ReactNode;
  category: string;
  date: string;
  href: string;
  title: ReactNode;
  lead: ReactNode;
  status?: ReactNode;
  progress?: ReactNode;
}

export interface IPropsAdd extends Partial<IProps> {
  type: EArticleItemType.ADD;
  children: ReactNode;
}

const ArticleItem = ({
  type,
  children,
  authors,
  authorship,
  category,
  date,
  href,
  title,
  lead,
  status,
  progress,
}: IProps | IPropsAdd) => {
  const LinkElement = (href ? NavLink : 'span') as keyof JSX.IntrinsicElements;
  return (
    <article className={styles.article}>
      <LinkElement to={href} target="_self" className={styles.articleLink}>
        {type === EArticleItemType.ADD ? (
          <div className={styles.articleAdd}>{children}</div>
        ) : (
          <>
            <header className={styles.articleHeader}>
              <div className={styles.articleCategory}>{category}</div>
              {type === EArticleItemType.ADMIN && (
                <>
                  {authorship}
                  {status}
                  {progress}
                </>
              )}
            </header>
            <div className={styles.articleContent}>
              <div className={styles.articleTitle}>{title}</div>
              <div className={styles.articleLead}>{lead}</div>
            </div>
            {authors?.length || date ? (
              <footer className={styles.articleFooter}>
                <ArticleAvatars authors={authors} />
                <ArticleAuthors authors={authors}>
                  <div>{date}</div>
                </ArticleAuthors>
              </footer>
            ) : null}
          </>
        )}
      </LinkElement>
    </article>
  );
};

ArticleItem.defaultProps = {
  type: EArticleItemType.DEFAULT,
  authors: [],
  authorship: undefined,
  children: undefined,
  status: undefined,
  progress: undefined,
};

export default ArticleItem;

export const ArticleContainer = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => <div className={`${styles.articles} ${className}`}>{children}</div>;

ArticleContainer.defaultProps = {
  className: '',
};
