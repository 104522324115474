import { Button } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
/* eslint-disable jsx-a11y/media-has-caption */
import { CookiesProvider, useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';

import {
  Animacja1,
  Animacja2,
  Animacja3,
  Animacja4,
  Animacja5,
} from '../../../../assets/materials/ebm';
import { ShowCookieAlert } from '../../../../components';
import { HeaderWithTriangles } from '../../../../components/HeaderWithTriangles';
import { customButtonThemeExtraBig } from '../../../../styles/buttons.mui';
import globalStyles from '../../../../styles/global.module.scss';
import styles from './Ebm.module.scss';

const Ebm = () => {
  const [cookies] = useCookies(['phisioKnowledge']);

  const modifier = 'main';
  const title = 'EBM';
  const [animationId, setAnimationIdVal] = useState(Animacja1);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const setAnimationId = (id: string) => {
    videoRef.current?.pause();
    setAnimationIdVal(id);
    videoRef.current?.play();
  };
  useEffect(() => {
    videoRef.current?.pause();

    videoRef.current?.load();
  }, [animationId]);
  return (
    <>
      <Helmet>
        <title>EBM - Projekt Infodemia KIF</title>
      </Helmet>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <div
          className={`${globalStyles.container} ${styles.container}`}
          data-app-type={modifier}
        >
          <HeaderWithTriangles title={title} className={styles.header} />

          <div
            className={styles.content}
            style={{ maxWidth: '900px', margin: 'auto', textAlign: 'center' }}
          >
            <p>
              <span style={{ fontStyle: 'italic' }}>
                evidence-based medicine (EBM)
              </span>
              <br />
              to medycyna oparta na dowodach naukowych, której celem jest
              wspomaganie podejmowania decyzji klinicznych wiarygodnymi dowodami
              naukowymi dotyczącymi skuteczności i bezpieczeństwa terapii.
              <br />
              <br />
              <br />
              <br />
            </p>
          </div>
          <div style={{ position: 'relative' }}>
            {!cookies.phisioKnowledge && <ShowCookieAlert />}

            <video
              width="100%"
              height="auto"
              autoPlay={false}
              controls
              loop
              ref={videoRef}
            >
              <source src={animationId} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div
              className={styles.animationsWrapper}
              style={{ display: 'flex', gap: '10px', marginTop: '20px' }}
            >
              <ThemeProvider theme={customButtonThemeExtraBig}>
                <Button
                  variant="contained"
                  onClick={() => setAnimationId(Animacja1)}
                >
                  1
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setAnimationId(Animacja2)}
                >
                  2
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setAnimationId(Animacja3)}
                >
                  3
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setAnimationId(Animacja4)}
                >
                  4
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setAnimationId(Animacja5)}
                >
                  {' '}
                  5
                </Button>
              </ThemeProvider>
            </div>
          </div>
        </div>
      </CookiesProvider>
    </>
  );
};

export default Ebm;
