/* eslint-disable simple-import-sort/imports */
import './App.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { Layout } from './components';
import { AppController } from './components/AppController';
import { ScrollToTop } from './components/ScrollToTop';
import Router from './router/Router';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'offlineFirst',
    },
  },
});

const Root = () => {
  return (
    <Layout>
      <Toaster position="top-right" reverseOrder={false} />
      <ScrollToTop />
      <Router />
      <AppController />
    </Layout>
  );
};

const router = createBrowserRouter([{ path: '*', Component: Root }]);

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
};

export default App;
