import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';

import { UserDto } from '../../../../api/models/UserDto';
import { getAuthors } from '../../../../api/services/user';
import { Avatar } from '../../../../assets/icons';
import { CustomLoading } from '../../../../components/CustomLoading';
import { Path } from '../../../../router/paths';
import globalStyles from '../../../../styles/global.module.scss';
import styles from './Authors.module.scss';

const Authors = () => {
  const {
    isLoading,
    error,
    data: authors,
  } = useQuery<UserDto[], AxiosError>({
    queryKey: ['authors'],
    queryFn: () => getAuthors().then((res) => res.data),
  });

  if (isLoading) {
    return <CustomLoading />;
  }

  if (error) {
    return <div>Wystąpił błąd... {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>Eksperci - Projekt Infodemia KIF</title>
      </Helmet>
      <div className={`${globalStyles.container} ${styles.arts}`}>
        <div className={`${styles.art} ${styles.artFirst}`}>
          <div className={styles.artCategorySub}>Poznaj</div>
          <div className={styles.artCategoryHeader}>Naszych autorów</div>
          <div className={styles.artCategoryDesc} />
        </div>
        {authors.map((author) => {
          return (
            author.id !== 18 &&
            author.id !== 19 &&
            author.id !== 1 &&
            author.id !== 3 && (
              <NavLink
                to={`${Path.AUTHOR}/${author.id}`}
                className={styles.navLink}
                key={author.id}
              >
                <div
                  className={styles.art}
                  style={{
                    backgroundImage: `url(${author.avatar || Avatar})`,
                  }}
                >
                  <div className={styles.artBox}>
                    {/* <div className={styles.artTitle}>Autor 1</div> */}
                    <div className={styles.artName}>{author.name}</div>
                  </div>
                </div>
              </NavLink>
            )
          );
        })}
        <div className={styles.placeholder} />
        <div className={styles.placeholder} />
        <div className={styles.placeholder} />
      </div>
    </>
  );
};

export default Authors;
