import { ReactNode } from 'react';

import { ArticleDto } from '../../api/models/ArticleDto';
import { ArticleTocDto } from '../../api/models/ArticleTocDto';
import styles from './ArticlePdf.module.scss';

interface IProps {
  article?: ArticleDto;
  articleToc?: ArticleTocDto[];
}

const ArticlePdf = ({ article, articleToc }: IProps) => {
  const generateToCPdf: (
    items: ArticleTocDto[],
    level?: number
  ) => ReactNode = (items, level = 1) => {
    return items.length
      ? items.map((item) => {
          const HeaderTag = `h${Math.min(
            6,
            level + 1
          )}` as keyof JSX.IntrinsicElements;

          return (
            <article key={item.id}>
              <header>
                <HeaderTag>{item.title}</HeaderTag>
              </header>

              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: item.content }}
              />

              {generateToCPdf(item.children, level + 1)}
            </article>
          );
        })
      : null;
  };

  const isToC = articleToc && articleToc.length > 0;

  return (
    <main className={styles.article}>
      {article ? (
        <>
          <h1 className={styles.title}>{article.title}</h1>
          <div className={styles.author}>Autor: {article.user.name}</div>

          <div className={styles.lead}>
            <p
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: article.lead }}
            />
          </div>

          <div
            className={styles.content}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: article.content }}
          />
        </>
      ) : null}

      {isToC && <div className={styles.toc}>{generateToCPdf(articleToc)}</div>}
    </main>
  );
};

ArticlePdf.defaultProps = {
  article: undefined,
  articleToc: undefined,
};

export default ArticlePdf;
