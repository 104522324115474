import { AxiosPromise } from 'axios';

import axiosInstance from '../axiosInstance';
import { baseAPIUrl } from '../config';
import { ArticleCategoryDto } from '../models/ArticleCategoryDto';
import { ArticleCommentDto } from '../models/ArticleCommentDto';
import { ArticleDto } from '../models/ArticleDto';
import {
  ArticlePositionDto,
  ArticlePositionPlace,
} from '../models/ArticlePositionDto';
import { ArticleTocDto } from '../models/ArticleTocDto';

export const getArticles = (): AxiosPromise<ArticleDto[]> => {
  return axiosInstance({
    url: `${baseAPIUrl}/articles`,
    method: 'GET',
  });
};

export const getArticlesPosition = (
  place: ArticlePositionPlace
): AxiosPromise<ArticlePositionDto[]> => {
  return axiosInstance({
    url: `${baseAPIUrl}/articles-position/${place}`,
    method: 'GET',
  });
};

export const getArticleCategories = (): AxiosPromise<ArticleCategoryDto[]> => {
  return axiosInstance({
    url: `${baseAPIUrl}/article-categories`,
    method: 'GET',
  });
};

export const getArticle = (
  id: number,
  password?: string
): AxiosPromise<ArticleDto> => {
  return axiosInstance({
    url: `${baseAPIUrl}/articles/${id}`,
    method: password ? 'POST' : 'GET',
    data: { password },
  });
};

export const getArticleToc = (id: number): AxiosPromise<ArticleTocDto[]> => {
  return axiosInstance({
    url: `${baseAPIUrl}/article-toc/${id}`,
    method: 'GET',
  });
};

export const createComment = (data: ArticleCommentDto): AxiosPromise<any> => {
  return axiosInstance({
    url: `${baseAPIUrl}/articles/comment`,
    method: 'POST',
    data,
  });
};
