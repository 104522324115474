import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Path } from '../../router/paths';
import styles from './SearchBar.module.scss';

const Search = styled('form')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '10ch',
      '&:focus': {
        width: '30ch',
      },
    },
    [theme.breakpoints.up('lg')]: {
      width: '20ch',
      '&:focus': {
        width: '40ch',
      },
    },
  },
}));

export const SEARCH_PARAM_NAME = 'szukaj';

const SearchBar = () => {
  const [params] = useSearchParams();
  const searchParam = params.get(SEARCH_PARAM_NAME);
  const [value, setValue] = useState(searchParam || '');

  useEffect(() => {
    setValue(searchParam || '');
  }, [searchParam]);

  return (
    <Search
      className={styles.searchBar}
      onSubmit={(ev) => {
        ev.preventDefault();

        const url = new URL(`${Path.ARTICLES}`, window.location.href);

        if (value) {
          url.searchParams.append(SEARCH_PARAM_NAME, value);
        }

        window.location.href = url.toString();
      }}
    >
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Wyszukaj artykuł"
        inputProps={{ 'aria-label': 'Wyszukaj artykuł' }}
        defaultValue={searchParam || ''}
        type="search"
        key={searchParam}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
    </Search>
  );
};

export default SearchBar;
