import { lockIcon } from '../../assets/icons';
import { Path } from '../../router/paths';

export type NavigationItem = {
  path: Path | string;
  label: string;
  icon?: any;
};

export type NavigationType = NavigationItem[];

export const navItems: NavigationType = [
  {
    path: `${Path.ROOT}`,
    label: 'Start',
  },
  {
    path: `${Path.ARTICLES}`,
    label: 'Publikacje',
  },
  {
    path: `${Path.AUTHORS}`,
    label: 'Eksperci',
  },
  {
    path: `${Path.INFOPAGE}`,
    label: 'O projekcie',
  },
  {
    path: `${Path.EBM}`,
    label: 'EBM',
  },
  {
    path: `${Path.NICE}`,
    label: 'NICE',
  },
  {
    path: `${Path.WHO}`,
    label: 'WHO',
  },
  {
    path: `${Path.EDUCATION}`,
    label: 'Materiały edukacyjne',
  },
  {
    path: `${Path.COCHRANE}`,
    label: 'Cochrane',
  },
  {
    path: `${Path.ADMIN}`,
    label: '',
    icon: lockIcon,
  },
];
