import styles from './HeaderSimple.module.scss';

interface IProps {
  title: string;
  color?: string;
}

// TODO add props
const HeaderSimple = (props: IProps) => {
  const { title, color } = props;
  return (
    <header
      className={`${styles.header}`}
      style={{ color, borderColor: color }}
    >
      <h2 className={styles.title} style={{ color, borderColor: color }}>
        <span>{title}</span>
      </h2>
    </header>
  );
};

HeaderSimple.defaultProps = {
  color: '#000000',
};

export default HeaderSimple;
