import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { toast } from 'react-hot-toast';

import { Path } from '../../router/paths';
import globalStyles from '../../styles/global.module.scss';
import copyToClipboard from '../../utils/copyToClipboard';
import { CustomTextField } from '../CustomTextField';
import styles from './ArticleEmbedDialog.module.scss';

export type TArticleEmbedDialogData = {
  id: number;
};

interface IProps {
  data?: TArticleEmbedDialogData;
  onClose: () => void;
}

const ArticleEmbedDialog = ({ data, onClose }: IProps) => {
  const { id } = data || {};

  const embedId = `infodemia-embed-${id}`;
  const embedCode = `
<!-- ${embedId} -->[newline]
<style>
  #${embedId} {
    width: 100%;
    min-height: 300px;
  }
</style>
<iframe src="${window.location.origin}${Path.ARTICLE_EMBED}/${id}" class="infodemia-embed" id="${embedId}" scrolling="no" frameborder="0"></iframe>
<script>
  window.addEventListener('message', function(event) {
    var id = '${embedId}'; 
    if (id in event.data) {
      document.getElementById(id).height = event.data[id].height;
    }
  });
</script>  
`
    .split('\n')
    .map((item) => {
      return item.trim();
    })
    .join('')
    .replace(/\t|\r/g, '')
    .replace(/\[newline\]/g, '\n');

  // dialog
  const handleDialogClose = () => {
    onClose();
  };

  const handleDialogConfirm = () => {
    copyToClipboard(embedCode)
      .then(() => {
        toast.success('Skopiowano kod HTML do schowka', {
          position: 'bottom-left',
        });
      })
      .catch(() => {
        toast.error(
          'Wystąpił błąd podczas kopiowania kodu HTML. Spróbuj skopiować ręcznie',
          {
            position: 'bottom-left',
          }
        );
      });
  };

  return (
    <Dialog
      className={styles.dialog}
      open={!!data}
      onClose={handleDialogClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Osadź artykuł na zewnętrznej stronie
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Skopiuj poniższy kod HTML i umieść go na swojej stronie.
        </DialogContentText>
        <div className={[globalStyles.form, styles.form].join(' ')}>
          <CustomTextField
            id="embed"
            label="Kod HTML"
            variant="outlined"
            rows={4}
            multiline
            className={[globalStyles.formRow, styles.code].join(' ')}
            inputProps={{
              readOnly: true,
              onClick: (event) => {
                (event.target as HTMLTextAreaElement).select();
              },
              onFocus: (event) => {
                (event.target as HTMLTextAreaElement).click();
              },
            }}
            value={embedCode}
            focused
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>Anuluj</Button>
        <Button
          onClick={handleDialogConfirm}
          variant="contained"
          color="primary"
          autoFocus
        >
          Kopiuj
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ArticleEmbedDialog.defaultProps = {
  data: undefined,
};

export default ArticleEmbedDialog;
