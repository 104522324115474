/* eslint-disable simple-import-sort/imports */
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import {
  ArticleDto,
  ArticleTarget,
  ArticleTargetText,
} from '../../api/models/ArticleDto';
import { ArticleTocDto } from '../../api/models/ArticleTocDto';
import {
  getArticle,
  getArticleToc,
  getArticles,
} from '../../api/services/article';
import { Avatar } from '../../assets/icons';
import { Path } from '../../router/paths';
import getEmbedRoute from '../../utils/getEmbedRoute';
import { ArticleCommentDialog } from '../ArticleCommentDialog';
import { TArticleCommentDialogData } from '../ArticleCommentDialog/ArticleCommentDialog';
import { ArticleEmbedDialog } from '../ArticleEmbedDialog';
import { TArticleEmbedDialogData } from '../ArticleEmbedDialog/ArticleEmbedDialog';
import { ArticleContainer, ArticleItem } from '../ArticleItem';
import { ArticlePasswordDialog } from '../ArticlePasswordDialog';
import { ArticlePdf } from '../ArticlePdf';
import { CustomLoading } from '../CustomLoading';
import { HeaderWithTriangles } from '../HeaderWithTriangles';
import { Share } from '../Share';
import { ShowCookieAlert } from '../ShowCookieAlert';
import styles from './Article.module.scss';

const Article = () => {
  const [cookies] = useCookies(['phisioKnowledge']);

  const { hash } = useLocation();
  const { id: paramId } = useParams();
  const id = parseInt(paramId!, 10);

  const isEmbed = !!getEmbedRoute();

  const [article, setArticle] = useState<ArticleDto>();
  const [articleToc, setArticleToc] = useState<ArticleTocDto[]>();

  const isEnabled = id !== undefined;

  const [password, setPassword] = useState<string>();

  const { isLoading, error, data } = useQuery<ArticleDto, AxiosError>({
    queryKey: ['article', id, password],
    queryFn: () => getArticle(id, password).then((res) => res.data),
    enabled: isEnabled,
    retry(failureCount, err) {
      return err.response?.status !== 401 && failureCount < 3;
    },
    refetchOnWindowFocus(query) {
      return query.state.error?.response?.status !== 401;
    },
  });

  const isEnabledToc = isEnabled && !!data;
  const {
    isLoading: isLoadingToc,
    error: errorToc,
    data: dataToc,
  } = useQuery<ArticleTocDto[], AxiosError>({
    queryKey: ['article-toc', id],
    queryFn: () => getArticleToc(id).then((res) => res.data),
    enabled: isEnabledToc,
  });

  useEffect(() => {
    setArticle(data);
  }, [JSON.stringify(data)]);

  useEffect(() => {
    setArticleToc(dataToc);
  }, [JSON.stringify(dataToc)]);

  const isEnabledArticles = !isEmbed && isEnabledToc;
  const { data: articles } = useQuery<ArticleDto[], AxiosError>({
    queryKey: ['articles', id],
    queryFn: () => getArticles().then((res) => res.data),
    enabled: isEnabledArticles,
  });

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const idAttr = entry.target.getAttribute('id');
        const linkEl = document.querySelector(
          `.${styles.tocNav} a[href="#${idAttr}"]`
        );
        if (!linkEl || !linkEl.parentElement) return;

        if (entry.intersectionRatio > 0) {
          linkEl.parentElement.dataset.isActive = '';
        } else {
          delete linkEl.parentElement.dataset.isActive;
        }
      });
    });

    const articleEls = document.querySelectorAll(`.${styles.toc} article[id]`);
    articleEls.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      observer.disconnect();
    };
  }, [JSON.stringify(article), JSON.stringify(articleToc)]);

  useEffect(() => {
    const hashContent = hash.substring(1);
    if (hashContent && articleToc && articleToc.length > 0) {
      const target = document.getElementById(hashContent);
      if (target) {
        setTimeout(() => {
          target.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          });
        });
      }
    }
  }, [JSON.stringify(article), JSON.stringify(articleToc), hash]);

  // dialog
  const [commentData, setCommentData] = useState<TArticleCommentDialogData>();
  const [embedData, setEmbedData] = useState<TArticleEmbedDialogData>();

  // generators
  const generateToCNav: (
    items: ArticleTocDto[],
    level?: number
  ) => ReactNode = (items, level = 1) => {
    return items.length
      ? items.map((item) => {
          const lvl = Math.min(3, level);
          const children = generateToCNav(item.children, level + 1);
          return (
            <article
              key={item.id}
              className={styles.tocNavChapter}
              data-level={lvl}
            >
              <a
                href={`#${item.id}`}
                className={styles.tocNavTitle}
                data-children={item.children.length}
              >
                <span>{item.title}</span>
              </a>

              {children ? (
                <div className={styles.tocNavChildren}>{children}</div>
              ) : null}
            </article>
          );
        })
      : null;
  };

  const generateToCContent: (
    items: ArticleTocDto[],
    level?: number
  ) => ReactNode = (items, level = 1) => {
    return items.length
      ? items.map((item) => {
          const HeaderTag = `h${Math.min(
            6,
            level + 1
          )}` as keyof JSX.IntrinsicElements;
          return (
            <article
              className={styles.tocArticle}
              key={item.id}
              id={`${item.id}`}
            >
              <header className={styles.tocHeader}>
                <HeaderTag>{item.title}</HeaderTag>
                <Share
                  className={styles.tocShare}
                  type="toc"
                  size="small"
                  data={{
                    title: item.title,
                    url: `${Path.ARTICLE}/${id}#${item.id}`,
                  }}
                  elementToPdf={<ArticlePdf articleToc={[item]} />}
                  onCommentClick={() => {
                    setCommentData({
                      articleId: id,
                      articleTocId: item.id,
                    });
                  }}
                />
              </header>

              <div
                className={styles.content}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: item.content }}
              />

              {generateToCContent(item.children, level + 1)}
            </article>
          );
        })
      : null;
  };

  const isToC = articleToc && articleToc.length > 0;

  const [passwordNotSet, setPasswordNotSet] = useState(false);

  const articleAuthors = useMemo(() => {
    if (article) {
      return [article.user, ...article.authors].filter((value, index, self) => {
        return self.findIndex((a) => a.id === value.id) === index;
      });
    }

    return [];
  }, [article]);

  if ((isEnabled && isLoading) || (isEnabledToc && isLoadingToc)) {
    return <CustomLoading />;
  }

  if (error && error.response?.status === 401) {
    if (passwordNotSet) {
      return (
        <div className={styles.passwordNeeded}>
          <span>Dostęp do tego artykułu wymaga podania hasła.</span>
          <Button variant="contained" onClick={() => setPasswordNotSet(false)}>
            Spróbuj ponownie
          </Button>
        </div>
      );
    }

    return (
      <ArticlePasswordDialog
        onSubmit={(pass) => {
          setPassword(pass);
        }}
        onCancel={() => {
          setPasswordNotSet(true);
        }}
      />
    );
  }

  if (error || errorToc) {
    return <div>Wystąpił błąd... {error?.message || errorToc?.message}</div>;
  }

  if (!article) {
    return null;
  }

  const articleUrl = `${Path.ARTICLE}/${id}`;

  return (
    <>
      <Helmet>
        <title>{article.title} - Projekt Infodemia KIF</title>
      </Helmet>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <article className={styles.article}>
          {isEmbed && (
            <NavLink
              className={styles.articleEmbeded}
              to={articleUrl}
              target="_top"
            >
              Przeczytaj artykuł na portalu:
              <mark>{articleUrl}</mark>
              <OpenInNewIcon fontSize="small" />
            </NavLink>
          )}
          <header className={styles.header}>
            <figure className={styles.headerFigure}>
              {article.image && (
                <img
                  className={styles.headerImage}
                  src={article.image}
                  alt={article.title}
                />
              )}
            </figure>
            <h1 className={styles.title}>
              <div className={styles.titleOuter}>
                <div className={styles.titleInner}>{article.title}</div>
              </div>
            </h1>
          </header>
          <div className={styles.container} style={{ position: 'relative' }}>
            {!cookies.phisioKnowledge &&
              article.target === ArticleTarget.Physiotherapist && (
                <ShowCookieAlert />
              )}
            <div className={styles.authorBoxList}>
              {articleAuthors.map(
                (author) =>
                  author.id !== 1 && (
                    <div className={styles.authorBox} key={author.id}>
                      <img
                        className={styles.authorAvatar}
                        src={author.avatar || Avatar}
                        alt=" "
                      />
                      <div className={styles.authorName}>{author.name}</div>
                    </div>
                  )
              )}
            </div>

            {article.create_time && (
              <time className={styles.date}>
                {new Date(article.create_time).toLocaleDateString('pl-PL')}
              </time>
            )}

            <Share
              className={styles.articleShare}
              type="article"
              data={{
                title: article.title,
                text: article.lead,
                url: `${Path.ARTICLE}/${article.id}`,
              }}
              elementToPdf={
                <ArticlePdf article={article} articleToc={articleToc} />
              }
              onEmbedClick={() => {
                setEmbedData({
                  id,
                });
              }}
              onCommentClick={() => {
                setCommentData({
                  articleId: id,
                });
              }}
            />

            <div className={styles.contentWrap}>
              {!isEmbed && isToC && (
                <nav className={styles.tocNav}>
                  {generateToCNav(articleToc)}
                </nav>
              )}

              <div className={styles.contentText}>
                <div className={styles.lead}>
                  <p
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: article.lead }}
                  />
                </div>

                <div
                  className={styles.content}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: article.content }}
                />

                {isToC && (
                  <div className={styles.toc}>
                    {generateToCContent(articleToc)}
                  </div>
                )}
              </div>
            </div>

            {!isEmbed && (
              <div>
                <HeaderWithTriangles
                  title="Podobne artykuły"
                  className={styles.similarArticlesHeader}
                />
                <ArticleContainer className={styles.similarArticlesContainer}>
                  {articles
                    ?.filter((art) => art.id !== article.id)
                    .sort((a) =>
                      a.category.id === article.category.id ? -1 : 1
                    )
                    .slice(0, 3)
                    .map((art) => {
                      const authors = [art.user, ...art.authors].filter(
                        (value, index, self) => {
                          return (
                            self.findIndex((a) => a.id === value.id) === index
                          );
                        }
                      );

                      return (
                        <ArticleItem
                          key={art.id}
                          category={`${ArticleTargetText[art.target]} / ${
                            art.category.name
                          }`}
                          date={art.update_time.toLocaleDateString('pl-PL')}
                          href={`${Path.ARTICLE}/${art.id}`}
                          title={art.title}
                          lead={art.lead}
                          authors={authors}
                        />
                      );
                    })}
                </ArticleContainer>
              </div>
            )}
          </div>

          <ArticleCommentDialog
            data={commentData}
            onClose={() => setCommentData(undefined)}
          />

          <ArticleEmbedDialog
            data={embedData}
            onClose={() => setEmbedData(undefined)}
          />
        </article>
      </CookiesProvider>
    </>
  );
};

export default Article;
