import { ArticleCategoryDto } from './ArticleCategoryDto';
import { AuthorDto } from './AuthorDto';

export enum ArticleStatus {
  Draft = 1,
  Published = 2,
}

export const ArticleStatusText: Record<ArticleStatus, string> = {
  [ArticleStatus.Draft]: 'Szkic',
  [ArticleStatus.Published]: 'Opublikowany',
};

export enum ArticleTarget {
  Patient = 1,
  Physiotherapist = 2,
}

export const ArticleTargetText: Record<ArticleTarget, string> = {
  [ArticleTarget.Patient]: 'Pacjent',
  [ArticleTarget.Physiotherapist]: 'Fizjoterapeuta',
};

export type ArticleDto = {
  id: number;
  user: AuthorDto;
  authors: AuthorDto[];
  title: string;
  lead: string;
  content: string;
  target: ArticleTarget;
  category: ArticleCategoryDto;
  status: ArticleStatus;
  update_time: Date;
  create_time: Date;
  image?: string | null;
};

export type ArticleAuthDto = ArticleDto & { password: string | null };

export type CreateUpdateArticleDto = ArticleDto & {
  imageFile?: File;
};
