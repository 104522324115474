import { Helmet } from 'react-helmet';

import { HeaderWithTriangles } from '../../components/HeaderWithTriangles';
import globalStyles from '../../styles/global.module.scss';
import styles from './Infopage.module.scss';

const Infopage = () => {
  const modifier = 'main';
  const title = 'O projekcie';

  return (
    <>
      <Helmet>
        <title>O projekcie - Projekt Infodemia KIF</title>
      </Helmet>
      <div
        className={`${globalStyles.container} ${styles.container}`}
        data-app-type={modifier}
      >
        <HeaderWithTriangles title={title} className={styles.header} />

        <div className={styles.content}>
          <p>
            Celem projektu jest wzmocnienie kompetencji fizjoterapeutów w
            zakresie przeciwdziałania zjawisku infodemii. Zadanie to,
            przygotowuje fizjoterapeutów do prowadzenia edukacji pacjentów w
            zakresie wdrażania wiedzy, postaw i zachowań właściwych do
            podejmowania decyzji zdrowotnych w oparciu o dane i informacje
            naukowe. Wskazuje argumenty do przeciwstawiania się poglądom,
            postawom i decyzjom opartym na fałszywych i szkodliwych
            informacjach. Projekt rozszerza i pogłębia wiedzę merytoryczną, w
            tym w szczególności tą, która dotyczy postępowania w kontekście
            COVID-19, jak i sposobów, metod i narzędzi upowszechniania.
            <br />
            <br />
            <br />
            Niniejsza platforma służy jako jedno z działań związanych z
            problematyką związaną z Infodemią, promując i przekazując wiedzę na
            temat postaw i zachowań właściwych dla podejmowania decyzji
            zdrowotnych w oparciu o dane i informacje naukowe. Serwis stanowi
            mobilny system informacyjno-użytkowy. Skierowany jest do
            fizjoterapeutów oraz pacjentów. Obejmuje obszar informacyjny oraz
            praktycznej pracy z pacjentem.
            <br />
            <br />
            <br />
            Obszar informacyjny: treści opracowanie przez ekspertów pracujących
            w ramach zadania „fizjoterapeuta edukator – przeciwdziałanie
            zjawisku infodemii”. Także: aktualności zawodowe, informacje nt.
            sytuacji epidemiologicznej, na temat szczepień, epidemiologii
            zakaźnej, immunologii, obowiązujących przepisach sanitarnych -
            zwłaszcza w kontekście wiedzy opartej na dowodach naukowych.
          </p>
        </div>
      </div>
    </>
  );
};

export default Infopage;
