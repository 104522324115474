import { createTheme } from '@mui/material/styles';

export const customButtonTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          background: '#164C8A',
          fontSize: '10px',
          fontWeight: 400,
          border: 'none',
          '&:hover': {
            background: '#6E759B',
          },
          '@media (min-width: 1000px)': {
            fontSize: '14px',
          },
        },
      },
    },
  },
});

export const customButtonThemeExtraBig = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          width: '100%',
          height: 'auto',
          padding: '20px',
          margin: '0px 0 0 0',
          fontSize: '12px',
          background: '#164C8A',
          '&:hover': {
            background: '#164C8A',
          },
          '@media (min-width: 1000px)': {
            width: 'auto',
            margin: '0 0 0 0px',
            fontSize: '14px',
          },
        },
      },
    },
  },
});
export const customButtonThemeExtraBigFull = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          width: '100%',
          height: 'auto',
          padding: '20px',
          margin: '0px 0 0 0',
          fontSize: '12px',
          background: '#164C8A',
          '&:hover': {
            background: '#164C8A',
          },
          '@media (min-width: 1000px)': {
            margin: '0 0 0 0px',
            fontSize: '14px',
          },
        },
      },
    },
  },
});
export const customButtonThemeBig = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          width: '100%',
          height: '55px',
          margin: '10px 0 0 0',
          fontSize: '12px',
          background: '#ef4161',
          '&:hover': {
            background: '#ee2b4f',
          },
          '@media (min-width: 1000px)': {
            width: '100px',
            margin: '0 0 0 10px',
            fontSize: '16px',
          },
        },
      },
    },
  },
});
export const customButtonErrorTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          background: '#d32f2f',
          color: '#fff',
          fontSize: '10px',
          fontWeight: 400,
          border: 'none',
          '&:hover': {
            background: '#900',
          },
          '@media (min-width: 1000px)': {
            fontSize: '14px',
          },
        },
      },
    },
  },
});
