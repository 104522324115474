/* eslint-disable jsx-a11y/media-has-caption */
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';

import {
  ArticlePositionDto,
  ArticlePositionPlace,
} from '../../api/models/ArticlePositionDto';
import { getArticlesPosition } from '../../api/services/article';
import { EMPTY_DOT_GIT } from '../../app/consts';
import {
  iconMain1,
  iconMain2,
  iconMain3,
  iconMain4,
  iconMain5,
  iconMain6,
  iconMain7,
  iconMain8,
} from '../../assets/icons/main_icons';
import { Animacja1 } from '../../assets/images/pics/education/anims';
import {
  CochraneLogo,
  CochraneLogoPion,
  NiceLogo,
  WhoLogo,
} from '../../assets/images/pics/main-page';
import education1 from '../../assets/materials/edukacyjne/fakty_i_mity/fakty_i_mity_glowna.jpg';
import { CustomLoading } from '../../components/CustomLoading';
import { Slider } from '../../components/Slider';
import { Path } from '../../router/paths';
import globalStyles from '../../styles/global.module.scss';
import styles from './Main.module.scss';

// const mockedProsData = [
//   {
//     icon: knowledgeIcon,
//     header: 'Baza wiedzy',
//     description:
//       'Krótki opis Krótki opis Krótki opis Krótki opis Krótki opis Krótki opis Krótki opis Krótki ',
//   },
//   {
//     icon: toolsIcon,
//     header: 'Narzędzia dla fizjoterapeuty',
//     description:
//       'Krótki opis Krótki opis Krótki opis Krótki opis Krótki opis Krótki opis Krótki opis Krótki ',
//   },
//   {
//     icon: fingerprintIcon,
//     header: 'Ocena zdrowia online dla pacjenta',
//     description:
//       'Krótki opis Krótki opis Krótki opis Krótki opis Krótki opis Krótki opis Krótki opis Krótki ',
//   },
//   {
//     icon: clockIcon,
//     header: 'Zdalna terapia',
//     description:
//       'Krótki opis Krótki opis Krótki opis Krótki opis Krótki opis Krótki opis Krótki opis Krótki ',
//   },
// ];
// eslint-disable-next-line import/no-dynamic-require
// const imageCochrane = require(`../../assets/images/pics/pigulki/${Math.floor(
//   Math.random() * 5 + 1
// )}.jpg`);
// eslint-disable-next-line import/no-dynamic-require
// const educationAnim = require(`../../assets/images/pics/education/anims/animacja_${Math.floor(
//   Math.random() * 3 + 1
// )}.mp4`);
const Main = () => {
  const { isLoading: isLoadingSlides, data: slidesData } = useQuery<
    ArticlePositionDto[],
    AxiosError
  >({
    queryKey: ['article-position', ArticlePositionPlace.MainSlider],
    queryFn: () =>
      getArticlesPosition(ArticlePositionPlace.MainSlider).then(
        (res) => res.data
      ),
  });

  const { isLoading: isLoadingRecommended, data: recommendedData } = useQuery<
    ArticlePositionDto[],
    AxiosError
  >({
    queryKey: ['article-position', ArticlePositionPlace.MainRecommended],
    queryFn: () =>
      getArticlesPosition(ArticlePositionPlace.MainRecommended).then(
        (res) => res.data
      ),
  });

  const recommended = recommendedData?.map((data) => ({
    content: data.article.content,
    id: data.article.id,
    img: data.article.image || EMPTY_DOT_GIT,
    title: data.article.title,
    lead: data.article.lead,
  }));

  const { isLoading: isLoadingPhysioTiles, data: physioTilesData } = useQuery<
    ArticlePositionDto[],
    AxiosError
  >({
    queryKey: ['article-position', ArticlePositionPlace.PhysioTiles],
    queryFn: () =>
      getArticlesPosition(ArticlePositionPlace.PhysioTiles).then(
        (res) => res.data
      ),
  });

  const { isLoading: isLoadingPatientTiles, data: patientTilesData } = useQuery<
    ArticlePositionDto[],
    AxiosError
  >({
    queryKey: ['article-position', ArticlePositionPlace.PatientTiles],
    queryFn: () =>
      getArticlesPosition(ArticlePositionPlace.PatientTiles).then(
        (res) => res.data
      ),
  });
  return (
    <div>
      <Helmet>
        <title>Projekt Infodemia KIF</title>
      </Helmet>
      {isLoadingSlides || !slidesData ? (
        <CustomLoading />
      ) : (
        <Slider
          slides={slidesData.map((slide) => ({
            id: slide.article.id,
            img: slide.article.image || '',
            title: slide.article.title,
          }))}
        />
      )}
      <div className={`${globalStyles.container} ${styles.infodemia}`}>
        <h1 className={styles.infodemiaHeader}>
          Czym jest Projekt Infodemia?{' '}
          <svg
            version="1.1"
            id="triangle"
            className={styles.headerTriangleIcon}
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 133.5 133.5"
            xmlSpace="preserve"
          >
            <path
              className="st0"
              fill="#ffffff"
              d="M0.2,0l0.3,133.5l115.3-67.2L0.2,0z"
            />
          </svg>{' '}
        </h1>
        <div className={styles.infodemiaMainText}>
          Niniejsza platforma służy jako jedno z działań związanych z
          problematyką związaną z Infodemią, promując wiedzę na temat postaw i
          zachowań właściwych dla podejmowania decyzji zdrowotnych, w oparciu o
          dane i informacje naukowe.
          <br />
          <br />
          Serwis stanowi mobilny system informacyjno-użytkowy. Skierowany jest
          do fizjoterapeutów oraz pacjentów i podzielony na obszar informacyjny
          oraz obszar praktycznej pracy z pacjentem.
        </div>
      </div>
      <div className={`${globalStyles.container}`}>
        <div className={styles.mainBoxes}>
          <div className={styles.mainBox}>
            <div className={styles.mainBoxIcon}>
              <img src={iconMain1} alt="Baza publikacji" />
            </div>
            Baza publikacji
            <br />
            <span>
              Dziesiątki fachowych publikacji poświęconych tematyce Infodemii
            </span>
          </div>

          <div className={styles.mainBox}>
            <div className={styles.mainBoxIcon}>
              <img src={iconMain2} alt="Sprawdzona wiedza" />{' '}
            </div>
            Sprawdzona wiedza
            <br />
            <span>
              Wszystkie publikacje mają oparcie w publikacjach naukowych i
              zawierają bibliografię
            </span>
          </div>

          <div className={styles.mainBox}>
            <div className={styles.mainBoxIcon}>
              <img src={iconMain3} alt="Fakty i mity" />{' '}
            </div>
            Fakty i mity
            <br />
            <span>
              Sprawdzisz czy zasłyszaną informację można uznać za prawdziwą
            </span>
          </div>
          <div className={styles.mainBox}>
            <div className={styles.mainBoxIcon}>
              <img src={iconMain4} alt="Podcasty" />{' '}
            </div>
            Podcasty
            <br />
            <span>
              Nie masz czasu czytać? Przygotowaliśmy podcasty dotyczące tematyki
              infodemii.
            </span>
          </div>
          <div className={styles.mainBox}>
            <div className={styles.mainBoxIcon}>
              <img src={iconMain5} alt="Prezentacje" />{' '}
            </div>
            Prezentacje
            <br />
            <span>Zdobywaj wiedzę w atrakcyjnej, multimedialnej formie</span>
          </div>
          <div className={styles.mainBox}>
            <div className={styles.mainBoxIcon}>
              <img src={iconMain6} alt="Wytyczne WHO i NICE" />{' '}
            </div>
            Wytyczne WHO i NICE
            <br />
            <span>
              Znajdziesz u nas zebrane i przetłumaczone oficjalne wytyczne
              światowych organizacji
            </span>
          </div>
          <div className={styles.mainBox}>
            <div className={styles.mainBoxIcon}>
              <img src={iconMain7} alt="Materiały wideo" />{' '}
            </div>
            Materiały wideo
            <br />
            <span>
              Specjalnie przygotowane materiały wideo dla fizjoterapeutów i
              pacjentów
            </span>
          </div>
          <div className={styles.mainBox}>
            <div className={styles.mainBoxIcon}>
              <img src={iconMain8} alt=" Uznani eksperci" />{' '}
            </div>
            Uznani eksperci
            <br />
            <span>
              Materiały są przygotowywane i weryfikowane przez wykładowców
              akademickich i praktyków
            </span>
          </div>
        </div>
      </div>{' '}
      {isLoadingRecommended || !recommended ? (
        <CustomLoading />
      ) : (
        recommended.length >= 3 && (
          <>
            {' '}
            <div
              className={`${globalStyles.container} ${styles.articles} ${styles.marginTop100}`}
            >
              <h1
                className={`${styles.infodemiaHeaderPublications} ${styles.infodemiaHeader}`}
              >
                Polecane publikacje{' '}
                <svg
                  version="1.1"
                  id="triangle"
                  className={styles.headerTriangleIcon}
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 133.5 133.5"
                  xmlSpace="preserve"
                >
                  <path
                    className="st0"
                    fill="#ffffff"
                    d="M0.2,0l0.3,133.5l115.3-67.2L0.2,0z"
                  />
                </svg>{' '}
              </h1>
            </div>
            <div
              className={`${globalStyles.container} ${styles.articles} ${styles.noMarginTop}`}
            >
              <NavLink
                to={`${Path.ARTICLE}/${recommended[0].id}`}
                target="_self"
                className={styles.articlesLeft}
              >
                <div className={styles.articleMainTop}>
                  <img
                    src={recommended[0].img}
                    alt=""
                    className={styles.articleMainImg}
                  />
                </div>
                <div className={styles.articleMainContent}>
                  <div className={styles.articleTitle}>
                    {recommended[0].title}
                  </div>
                  <div
                    className={styles.articleDesc}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: recommended[0].content }}
                  />
                </div>
              </NavLink>
              <div className={styles.articlesRight}>
                <NavLink
                  to={`${Path.ARTICLE}/${recommended[1].id}`}
                  target="_self"
                  className={styles.articleRight}
                >
                  <div className={styles.articleMainContent}>
                    <div className={styles.articleTitle}>
                      {recommended[1].title}
                    </div>
                    <div
                      className={styles.articleDesc}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: recommended[1].content,
                      }}
                    />
                  </div>
                  <div className={styles.articleRightImgBox}>
                    <img
                      src={recommended[1].img}
                      alt=""
                      className={styles.articleMainImg}
                    />
                  </div>
                </NavLink>
                <NavLink
                  to={`${Path.ARTICLE}/${recommended[2].id}`}
                  target="_self"
                  className={styles.articleRight}
                >
                  <div className={styles.articleMainContent}>
                    <div className={styles.articleTitle}>
                      {recommended[2].title}
                    </div>
                    <div
                      className={styles.articleDesc}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: recommended[2].content,
                      }}
                    />
                  </div>
                  <div className={styles.articleRightImgBox}>
                    <img
                      src={recommended[2].img}
                      alt=""
                      className={styles.articleMainImg}
                    />
                  </div>
                </NavLink>

                {/* <NavLink
                  to={`${Path.ARTICLE}/${recommended[2].id}`}
                  target="_self"
                  className={styles.articleRight}
                >
                  <div className={styles.articleMainContent}>
                    <div className={styles.articleTitle}>
                      Ból pleców - fizjoterapia czy masaż?
                    </div>
                    <div className={styles.articleDesc}>
                      Lorem ipsum dolor sit amet consectetur. Mauris facilisis
                      vitae mollis tristique aliquam. Non porttitor nulla porta
                      sed id placerat tristique elementum. Posuere aenean dis ut
                      convallis. Lorem ipsum dolor sit amet consectetur. Mauris
                      facilisis vitae mollis tristique aliquam. Non porttitor
                      nulla porta sed id placerat tristique elementum. Posuere
                      aenean dis ut convallis. Lorem ipsum dolor sit amet
                      consectetur. Mauris facilisis vitae mollis tristique
                      aliquam. Non porttitor nulla porta sed id placerat
                      tristique elementum. Posuere aenean dis ut convallis.
                    </div>
                  </div>{' '}
                  <div className={styles.articleRightImgBox}>
                    <img
                      src={recommended[2].img}
                      alt=""
                      className={styles.articleMainImg}
                    />
                  </div>
                </NavLink> */}
              </div>
            </div>
          </>
        )
      )}
      {/* <div className={`${globalStyles.container}`}>
        <HeaderWithTriangles title="Zaufane źródła" className={styles.header} />
      </div> */}
      <div className={`${globalStyles.container}`}>
        <div className={styles.partnersWrap}>
          <a
            className={`${styles.partnersWrapPartner} ${styles.partnersWrapCochrane}`}
            href="/cochrane"
          >
            <div className={styles.partnersWrapCochraneLogo}>
              {' '}
              <img
                src={CochraneLogo}
                className={styles.cochraneLogo}
                alt="Cochrane"
              />
              <img
                src={CochraneLogoPion}
                alt="Cochrane"
                className={styles.cochraneLogoPion}
              />
            </div>
            <div className={styles.partnersWrapPartnerText}>
              <h4>
                Powrót do sprawności ruchowej po operacji złamania szyjki kości
                udowej u dorosłych
              </h4>
              W przeglądzie systematycznym obejmującym 4059 uczestników (80%
              kobiet, średnia wieku 80 lat) podsumowano dane naukowe o
              zróżnicowanej pewności (od bardzo niskiej do wysokiej). Wnioski
              wskazują, że dodatkowy trening mobilności w niewielkim, ale
              znaczącym stopniu pomaga pacjentom po operacyjnym leczeniu
              złamania szyjki kości udowej w powrocie do sprawności ruchowej, w
              szczególności w zakresie tempa chodu.
            </div>
          </a>
          <div className={styles.partnersWrapOthers}>
            <div className={styles.partnersWrapPartner}>
              <div className={styles.partnersWrapPartnerLogo}>
                {' '}
                <img src={WhoLogo} alt="WHO" />
              </div>
              <div
                className={`${styles.partnersWrapPartnerLinks} ${styles.partnersWrapPartnerLinksWho}`}
              >
                <a href="/wytyczne-who">
                  Dla fizjoterapeuty
                  <span>(Dokumenty, broszury, prezentacje)</span>
                </a>
                <a href="/wytyczne-who">
                  Dla pacjenta <span>(Broszury, materiały graficzne)</span>
                </a>
              </div>
            </div>
            <div className={styles.partnersWrapPartner}>
              <div className={styles.partnersWrapPartnerLogo}>
                {' '}
                <img src={NiceLogo} alt="Nice" />
              </div>
              <div
                className={`${styles.partnersWrapPartnerLinks} ${styles.partnersWrapPartnerLinksNice}`}
              >
                <a href="/wytyczne-nice">
                  Dla fizjoterapeuty{' '}
                  <span>(Broszury, prezentacje, infografiki, dokumenty)</span>
                </a>
                <a href="/wytyczne-nice">
                  Dla pacjenta{' '}
                  <span>
                    (Broszury, prezentacje, infografiki, materiały graficzne)
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: '100px',
          paddingBottom: '100px',
        }}
      >
        <div className={`${globalStyles.container}`}>
          <h1
            className={`${styles.infodemiaHeaderEducation} ${styles.infodemiaHeader}`}
          >
            Materiały edukacyjne{' '}
            <svg
              version="1.1"
              id="triangle"
              className={styles.headerTriangleIcon}
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 133.5 133.5"
              xmlSpace="preserve"
            >
              <path
                className="st0"
                fill="#ffffff"
                d="M0.2,0l0.3,133.5l115.3-67.2L0.2,0z"
              />
            </svg>{' '}
          </h1>
        </div>
        <div className={`${globalStyles.container}`}>
          <div className={styles.educationImgWrap}>
            <img
              className={styles.educationImg}
              // eslint-disable-next-line import/no-dynamic-require, global-require
              src={education1}
              alt=""
            />
          </div>
          <div className={styles.educationBoxes}>
            <a
              className={styles.educationBox}
              href="/materialy-edukacyjne#podcasty"
            >
              <div className={styles.cat}>DLA FIZJOTERAPEUTY</div>
              <span>Podcasty</span>
            </a>
            <a
              className={styles.educationBox}
              href="/materialy-edukacyjne#infografiki"
            >
              {' '}
              <div className={styles.cat}>DLA FIZJOTERAPEUTY</div>
              <span>Prezentacje</span>
            </a>
            <a
              className={styles.educationBox}
              href="/materialy-edukacyjne#dokumenty"
            >
              {' '}
              <div className={styles.cat}>DLA FIZJOTERAPEUTY</div>
              <span>Dokumenty</span>
            </a>
          </div>
        </div>
        <div className={`${globalStyles.container}`}>
          <div className={styles.educationBoxes}>
            <a
              className={styles.educationBox}
              href="/materialy-edukacyjne#podcasty"
            >
              {' '}
              <div className={styles.cat}>DLA PACJENTA</div>
              <span>Podcasty</span>
            </a>
            <a
              className={styles.educationBox}
              href="/materialy-edukacyjne#infografiki"
            >
              {' '}
              <div className={styles.cat}>DLA PACJENTA</div>
              <span>Infografiki</span>
            </a>
            <a
              className={styles.educationBox}
              href="/materialy-edukacyjne#filmy"
            >
              {' '}
              <div className={styles.cat}>DLA PACJENTA</div>
              <span>Filmy</span>
            </a>
          </div>
        </div>{' '}
        <video
          controls
          loop
          autoPlay
          muted
          width="100%"
          style={{ marginTop: '100px' }}
        >
          <source src={Animacja1} type="video/mp4" />
        </video>
      </div>
      {isLoadingPhysioTiles || !physioTilesData ? (
        <CustomLoading />
      ) : (
        physioTilesData.length > 0 && (
          <div
            className={`${globalStyles.container}  ${styles.patientArts} ${styles.arts}`}
          >
            <div className={`${styles.art} ${styles.artFirst}`}>
              <div className={styles.artCategorySub}>Wiedza</div>
              <div className={styles.artCategoryHeader}>Dla fizjoterapeuty</div>
              <svg
                className={styles.artCategoryHeaderIcon}
                version="1.1"
                id="Warstwa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 595.3 841.9"
                xmlSpace="preserve"
              >
                <path
                  className="st0"
                  fill="#ef4161"
                  d="M240.5,356.4l0.3,133.5l115.3-67.2L240.5,356.4z"
                />
              </svg>
            </div>
            {physioTilesData.map((data) => {
              return (
                <NavLink
                  to={`${Path.ARTICLE}/${data.article.id}`}
                  target="_self"
                  className={styles.navLink}
                  key={data.id}
                >
                  <div className={styles.art}>
                    <img
                      src={data.article.image || EMPTY_DOT_GIT}
                      className={styles.artImg}
                      alt=""
                    />
                    <div className={styles.artBoxBg} />

                    <div className={styles.artBox}>
                      <div className={styles.artCategory}>
                        {data.article.category.name}
                      </div>
                      <div className={styles.artTitle}>
                        {data.article.title}
                      </div>
                    </div>
                  </div>
                </NavLink>
              );
            })}
            <div className={styles.placeholder} />
            <div className={styles.placeholder} />
            <div className={styles.placeholder} />
          </div>
        )
      )}
      {isLoadingPatientTiles || !patientTilesData ? (
        <CustomLoading />
      ) : (
        patientTilesData.length < 0 && (
          <div
            className={`${globalStyles.container} ${styles.arts}  ${styles.patientArts}`}
          >
            <div className={styles.artFirst}>
              <div className={styles.artCategorySub}>Wiedza</div>
              <div className={styles.artCategoryHeader}>Dla pacjenta</div>
              <svg
                version="1.1"
                id="Warstwa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 595.3 841.9"
                xmlSpace="preserve"
                className={styles.artCategoryHeaderIcon}
              >
                <path
                  className="st0"
                  fill="#ef4161"
                  d="M240.5,356.4l0.3,133.5l115.3-67.2L240.5,356.4z"
                />
              </svg>
            </div>
            {patientTilesData.map((data) => {
              return (
                <NavLink
                  to={`${Path.ARTICLE}/${data.article.id}`}
                  target="_self"
                  className={styles.navLink}
                  key={data.id}
                >
                  <div className={styles.art}>
                    <img
                      src={data.article.image || EMPTY_DOT_GIT}
                      className={styles.artImg}
                      alt=""
                    />
                    <div className={styles.artBoxBg} />

                    <div className={styles.artBox}>
                      <div className={styles.artCategory}>
                        {data.article.category.name}
                      </div>
                      <div className={styles.artTitle}>
                        {data.article.title}
                      </div>
                    </div>
                  </div>
                </NavLink>
              );
            })}

            <div className={styles.placeholder} />
            <div className={styles.placeholder} />
            <div className={styles.placeholder} />
          </div>
        )
      )}
    </div>
  );
};
export default Main;
