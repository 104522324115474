import axios, {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig,
} from 'axios';

import { Path } from '../router/paths';
import { isAuthRoute } from '../utils/isAuthRoute';

const axiosInstance: AxiosInstance = axios.create({
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  transformResponse: (data) => {
    if (data === '') return '';
    if (data === undefined) return undefined;
    return JSON.parse(data, (key, value) => {
      if (['update_time', 'create_time'].includes(key)) {
        return new Date(value);
      }

      return value;
    });
  },

  // axios issue: https://github.com/axios/axios/issues/89
  data: {},
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (isAuthRoute(error.config?.url) && error.response?.status === 401) {
      window.location.href = Path.LOGIN;
    }

    throw error;
  }
);

const setAuthToken = (request: InternalAxiosRequestConfig<any>) => {
  const authToken = localStorage.getItem('authToken');

  if (isAuthRoute(request.url) && authToken) {
    request.headers.Authorization = `Bearer ${authToken}`;
  }

  return request;
};

axiosInstance.interceptors.request.use(setAuthToken);

export default axiosInstance;
