import { CookiesProvider } from 'react-cookie';
import { Helmet } from 'react-helmet';

import pigula1 from '../../../../assets/images/pics/pigulki/1.jpg';
import pigula2 from '../../../../assets/images/pics/pigulki/2.jpg';
import pigula3 from '../../../../assets/images/pics/pigulki/3.jpg';
import pigula4 from '../../../../assets/images/pics/pigulki/4.jpg';
import pigula5 from '../../../../assets/images/pics/pigulki/5.jpg';
import pigula6 from '../../../../assets/images/pics/pigulki/6.jpg';
import { HeaderWithTriangles } from '../../../../components/HeaderWithTriangles';
import globalStyles from '../../../../styles/global.module.scss';
import styles from './Cochrane.module.scss';

const Cochrane = () => {
  const modifier = 'main';
  const title = 'Cochrane';

  return (
    <>
      <Helmet>
        <title>Cochrane - Projekt Infodemia KIF</title>
      </Helmet>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <div
          className={`${globalStyles.container} ${styles.container}`}
          data-app-type={modifier}
        >
          <HeaderWithTriangles title={title} className={styles.header} />
          <div
            className={styles.content}
            style={{ maxWidth: '900px', margin: 'auto', textAlign: 'center' }}
          >
            <p>
              Cochrane to międzynarodowa sieć z siedzibą w Wielkiej Brytanii,
              której celem jest dostarczanie wysokiej jakości informacji dla
              wszystkich zainteresowanych podejmowaniem decyzji dotyczących
              zdrowia. Dane naukowe Cochrane są wykorzystywane zarówno przez
              klinicystów, pacjentów, opiekunów, naukowców czy polityków i są
              skutecznym narzędziem do wzmacniania wiedzy oraz do podejmowania
              decyzji z zakresu opieki zdrowotnej. Cochrane to społeczność
              naukowców, pracowników ochrony zdrowia, pacjentów i osób
              zainteresowanych poprawą wyników zdrowotnych z ponad 190 krajów.
              <br />
              Więcej:{' '}
              <a
                href="https://poland.cochrane.org/pl"
                target="_blank"
                rel="noreferrer"
              >
                https://poland.cochrane.org/pl
              </a>
              <br />
            </p>
          </div>
          <HeaderWithTriangles
            title="Pigułki Cochrane"
            className={styles.header}
          />
          <div style={{ position: 'relative' }}>
            {' '}
            <a
              href="https://doi.org/10.1002/14651858.CD013114.pub2
"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="pigulka cochrane"
                src={pigula1}
                style={{ width: '100%' }}
              />
            </a>
            <br />
            <a
              href="https://www.cochrane.org/CD012996/STROKE_interventions-reduce-sedentary-behaviour-after-stroke

"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="pigulka cochrane"
                src={pigula2}
                style={{ width: '100%' }}
              />
            </a>{' '}
            <br />
            <a
              href="https://doi.org/10.1002/14651858.CD006311.pub4

"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="pigulka cochrane"
                src={pigula3}
                style={{ width: '100%' }}
              />
            </a>{' '}
            <br />
            <a
              href="https://www.cochrane.org/CD003316/STROKE_physical-fitness-training-stroke-survivors

"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="pigulka cochrane"
                src={pigula4}
                style={{ width: '100%' }}
              />
            </a>{' '}
            <br />
            <a
              href="https://doi.org/10.1002/14651858.CD012475.pub2

"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="pigulka cochrane"
                src={pigula5}
                style={{ width: '100%' }}
              />
            </a>{' '}
            <br />
            <a
              href="https://doi.org/10.1002/14651858.CD001704.pub5

"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="pigulka cochrane"
                src={pigula6}
                style={{ width: '100%' }}
              />
            </a>{' '}
            <br />
          </div>
        </div>
      </CookiesProvider>
    </>
  );
};

export default Cochrane;
