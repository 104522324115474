/* eslint-disable global-require */
import { Button } from '@mui/material';
import { ThemeProvider } from '@mui/system';
/* eslint-disable-next-line simple-import-sort/imports */
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';

import { customButtonThemeExtraBig } from '../../../../styles/buttons.mui';

const ViewPdf = () => {
  // const { hash } = useLocation();
  const { pdfname: paramPdfname } = useParams();
  const pdfname = paramPdfname;
  let pdf;
  let pageTitle;
  // const [article, setArticle] = useState<ArticleDto>();
  // const [articleToc, setArticleToc] = useState<ArticleTocDto[]>();

  // useEffect(() => {
  //   setArticle(data);
  // }, [JSON.stringify(data)]);

  // useEffect(() => {
  //   const hashContent = hash.substring(1);
  //   if (hashContent && articleToc && articleToc.length > 0) {
  //     const target = document.getElementById(hashContent);
  //     if (target) {
  //       setTimeout(() => {
  //         target.scrollIntoView({
  //           block: 'start',
  //           behavior: 'smooth',
  //         });
  //       });
  //     }
  //   }
  // }, [JSON.stringify(article), JSON.stringify(articleToc), hash]);
  if (pdfname && pdfname === 'broszura_nice_fizjoterapeuta') {
    pdf = require(`../../../../assets/materials/nice/wytyczne/fizjoterapeuta.pdf`);
    pageTitle = 'Broszura NICE - fizjoterapeuta';
  }
  if (pdfname && pdfname === 'broszura_nice_pacjent') {
    pdf = require(`../../../../assets/materials/nice/wytyczne/pacjent.pdf`);
    pageTitle = 'Broszura NICE - pacjent';
  }
  if (pdfname && pdfname === 'nice-wytyczne') {
    pdf = require(`../../../../assets/materials/nice/wytyczne/nice-wytyczne.pdf`);
    pageTitle = 'Wytyczne NICE - fizjoterapeuta';
  }
  if (pdfname && pdfname === 'nice-wytyczne-suplement') {
    pdf = require(`../../../../assets/materials/nice/wytyczne/nice-wytyczne-suplement.pdf`);
    pageTitle = 'Wytyczne NICE - suplement';
  }
  if (pdfname && pdfname === 'nice-wytyczne-dokumenty-dodatkowe') {
    pdf = require(`../../../../assets/materials/nice/wytyczne/nice-wytyczne-dokumenty-dodatkowe.pdf`);
    pageTitle = 'Broszura NICE - dokumenty dodatkowe';
  }
  if (pdfname && pdfname === 'who-broszura-fizjoterapeuta') {
    pdf = require(`../../../../assets/materials/who/broszury/who_broszura_fizjoterapeuta.pdf`);
    pageTitle = 'Broszura WHO - fizjoterapeuta';
  }
  if (pdfname && pdfname === 'who-broszura-pacjent') {
    pdf = require(`../../../../assets/materials/who/broszury/who_broszura_pacjenci.pdf`);
    pageTitle = 'Broszura WHO - pacjent';
  }
  if (pdfname && pdfname === 'who-wytyczne') {
    pdf = require(`../../../../assets/materials/who/wytyczne/wytyczne_who_fizjoterapeuci.pdf`);
    pageTitle = 'Wytyczne WHO';
  }
  if (pdfname && pdfname === 'jak-walczyc-z-infodemia') {
    pdf = require(`../../../../assets/materials/edukacyjne/dokumenty/jak_walczyc.pdf`);
    pageTitle = 'Jak walczyć z infodemią';
  }
  if (pdfname && pdfname === 'typy-szczepionek') {
    pdf = require(`../../../../assets/materials/edukacyjne/dokumenty/typy_szczepionek.pdf`);
    pageTitle = 'Typy szczepionek';
  }
  return (
    <>
      <Helmet>
        <title>{pageTitle} - Projekt Infodemia KIF</title>
      </Helmet>
      <div>
        {pdf && (
          <div>
            <iframe
              title="PDF"
              src={pdf}
              style={{ width: '100%', height: '1000px' }}
              frameBorder="0"
            />

            {/* <iframe
            title="PDF"
            src={`http://docs.google.com/gview?url=${require(`../../../../assets/materials/nice/wytyczne/fizjoterapeuta.pdf`)}&embedded=true`}
            style={{ width: '100%', height: '1000px' }}
            frameBorder="0"
          /> */}

            <h4 style={{ textAlign: 'center' }}>
              NIE WIDZISZ PLIKU? <br />
              <ThemeProvider theme={customButtonThemeExtraBig}>
                <Button
                  variant="contained"
                  component={Link}
                  to={pdf}
                  target="_blank"
                >
                  KLIKNIJ TUTAJ I OTWÓRZ W NOWYM OKNIE
                </Button>
              </ThemeProvider>
            </h4>
          </div>
        )}
      </div>
    </>
  );
};

export default ViewPdf;
