import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ComponentType, FC } from 'react';

import { UserDto } from '../api/models/UserDto';
import { getProfile } from '../api/services/auth';
import { CustomLoading } from '../components/CustomLoading';

interface PrivateRouteProps {
  component: ComponentType;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ component: RouteComponent }) => {
  const { isLoading, error } = useQuery<UserDto, AxiosError>({
    queryKey: ['profile'],
    queryFn: () => getProfile().then((res) => res.data || {}),
    retry: false,
  });

  if (isLoading) {
    return <CustomLoading />;
  }

  if (error) {
    return null;
  }

  return <RouteComponent />;
};

export default PrivateRoute;
