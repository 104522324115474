import { useCookies } from 'react-cookie';

import styles from './ShowCookieAlert.module.scss';

const ShowCookieAlert = () => {
  const [cookies, setCookie] = useCookies(['phisioKnowledge']);

  function changeCookie() {
    setCookie('phisioKnowledge', 1);
  }

  return (
    <div className={styles.alert}>
      {' '}
      {!cookies.phisioKnowledge && (
        <div>
          {' '}
          <strong>
            Ta część aplikacji dostępna jest jedynie dla fizjoterapeutów.
          </strong>{' '}
          <br />
          Klikając poniżej potwierdzasz, że posiadasz prawo do wykonywania
          zawodu fizjoterapeuty.
          <br />
          <br />
          <button
            type="button"
            className={styles.acceptButton}
            onClick={changeCookie}
          >
            Potwierdzam
          </button>
        </div>
      )}
    </div>
  );
};

export default ShowCookieAlert;
